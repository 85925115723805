<div class="login-card-div, mat-elevation-z8" fxFlexFill fxLayout="column" fxLayoutAlign="center center">
  <mat-card fxLayout="column" fxLayoutGap="1em">
    <mat-card-header>
      <mat-card-title fxLayout="row" fxFlexLayoutAlign="center center" fxFill>
        <div fxLayout="row" fxFlexLayoutAlign="center center">
          Inloggning
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayoutGap="1em" mat-dialog-content [formGroup]="loginForm" fxLayout="column">
        <mat-form-field>
          <mat-label>Användarnamn</mat-label>
          <input matInput formControlName="username" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Användarnamn måste anges</div>
          </div>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Lösenord</mat-label>
          <input matInput type="password" formControlName="password" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Lösenord måste anges</div>
          </div>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-actions fxLayout="column" fxLayoutGap="1em" fxFlexLayoutAlign="space-around center">
      <button mat-stroked-button color="primary" [disabled]="loading" (click)="onSubmit()">
        Login
      </button>
      <br>
    </mat-card-actions>
  </mat-card>
</div>
