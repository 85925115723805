import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TasklistDataService } from '../../services/tasklist-data.service';
import { PersonnelDataService } from 'src/app/personnellist/personnel-data.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-settaskstate-dialog',
  templateUrl: './settaskstate-dialog.component.html',
  styleUrls: ['./settaskstate-dialog.component.css']
})
export class SettaskstateDialogComponent implements OnInit{
  form: UntypedFormGroup;
  dialogTitle: String = "UNDEFINED";

  constructor(
    private dialogRef: MatDialogRef<SettaskstateDialogComponent>,
    private dialog: MatDialog,
    private tasklistService: TasklistDataService,
    private personnelService: PersonnelDataService,
    @Inject(MAT_DIALOG_DATA) public data: Task
  ) {

    this.dialogTitle = "Kvittera uppgift";
    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      entry: new UntypedFormControl("", [Validators.required, Validators.minLength(1)]),
    });
  }


  ngOnInit(): void {
  }

  cancelDialog(){
    this.dialogRef.close();
  }

  saveDialog(){
    this.dialogRef.close(this.form.value);
  }
}
