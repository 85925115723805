import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Personnel, PersonnelDataService } from '../../personnel-data.service';

@Component({
  selector: 'app-edit-personnel-dialog',
  templateUrl: './edit-personnel-dialog.component.html',
  styleUrls: ['./edit-personnel-dialog.component.css']
})
export class EditPersonnelDialogComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<EditPersonnelDialogComponent>,
    private dialog: MatDialog,
    private pdService: PersonnelDataService,
    @Inject(MAT_DIALOG_DATA) public data: Personnel
  ) {

    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      first_name: new UntypedFormControl(data.first_name),
      last_name: new UntypedFormControl(data.last_name),
      username: new UntypedFormControl(data.username),
      email: new UntypedFormControl(data.email),
      phone_number_1: new UntypedFormControl(data.phone_number_1),
    });
  }

  ngOnInit(): void {
  }

  cancelDialog(){
    this.dialogRef.close();
  }

  saveDialog(){
    this.dialogRef.close(this.form.value);
  }
}
