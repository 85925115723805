import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Task, Tasklist, TasklistDataService } from '../../services/tasklist-data.service';
import { NavService } from 'src/app/nav/services/nav.service';
import { Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Personnel, PersonnelDataService } from 'src/app/personnellist/personnel-data.service';

@Component({
  selector: 'app-tasklisttable',
  templateUrl: './tasklisttable.component.html',
  styleUrls: ['./tasklisttable.component.css']
})
export class TasklisttableComponent {
  @Input() tasklistDataSource: MatTableDataSource<Task>;
  @Input() showNewTaskButton: boolean = true;

  displayedColumns: string[] = ['name', 'assigned', 'created', 'createdBy'];

  initialSelection = [];
  allowMultiSelect = true;
  selection: SelectionModel<Task>;
  activeTask: Task = null;

  constructor(
    private navService: NavService,
    private route: Router,
    private cdRef: ChangeDetectorRef,
    private personelService: PersonnelDataService,) {
  }

  ngOnInit(): void {
    this.selection = new SelectionModel<Task>(this.allowMultiSelect, this.initialSelection);
   }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskList != null) {
//      console.log("-------------------");
//      console.log("tasklisttable.component.ts ngOnChanges()");
//      console.log("tasklisttable.component.ts ngOnChanges() data: " + JSON.stringify(this.tasklistDataSource.data));
//      console.log("tasklisttable.component.ts ngOnChanges() changes: " + JSON.stringify(changes.taskList.currentValue));
//      console.log("-------------------");
      this.tasklistDataSource.data = changes.taskList.currentValue;

    }
    //this.cdRef.markForCheck();
  }

  showTaskDetail(task: Task): void {
    this.navService.showDetailNav();
    this.activeTask = task;
    this.route.navigate(['', { outlets: { detailOutlet: ['taskdetail', task.id] } }]);
  }

  getNameFromId(id: number): any {
    return this.personelService.getNameFromId(id);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = 1;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
//    this.isAllSelected() ?
//      this.selection.clear() :
//      this.taskList.forEach(row => this.selection.select(row));
  }

  toggleSelect(task: Task) {
    this.selection.toggle(task);

//    console.log("toggleSelect()" + this.selection.selected.length);

    if (this.selection.selected.length == 1) {
      this.navService.showDetailNav();
      this.showTaskDetail(this.selection.selected[0]);
    }
    else {
      this.navService.hideDetailNav();
    }
  }

  sortData(event) {
//    console.log("sortData() event: " + JSON.stringify(event));
//    console.log("sortData() data: " + JSON.stringify(this.tasklistDataSource.data));

    this.tasklistDataSource.data = this.tasklistDataSource.data.sort((a, b) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'name': return this.compare(a.title, b.title, isAsc);
        case 'assigned': return this.compare(a.assigned_to, b.assigned_to, isAsc);
        case 'created': return this.compare(a.created_date, b.created_date, isAsc);
        case 'createdBy': return this.compare(a.created_by, b.created_by, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string | Date | Personnel, b: number | string | Date | Personnel, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
