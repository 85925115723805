import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import Tile from 'ol/layer/Tile';
import {fromLonLat} from 'ol/proj.js';
import { RealestateData } from '../buildingmanager/services/buildingmanager-data.service';


@Component({
  selector: 'app-map-component',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @Input() realestate: RealestateData;
  
  map: Map;
  targetId: string;

  constructor() {

  }


  ngOnInit(){
    this.targetId = "map-" + this.realestate.id;    
  }

  ngAfterViewInit(){
    this.initilizeMap(this.realestate);
  }

  initilizeMap(realestate: RealestateData) {
    console.log("creating map object");
    this.map = new Map({
      target: this.targetId,
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],      
      view: new View({
        center: [1597176.7226239955,7470458.010745565 ],
        zoom: 16
      })
    });
  }


  processMapEvent(event: any){
    let coordinate = this.map.getEventCoordinate(event);
    console.log("Map event : " + event);
    console.log("Map event at: " + coordinate);
 }
}