import { NavService } from './../../../nav/services/nav.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BuildingmanagerDataService, RealestateData } from 'src/app/buildingmanager/services/buildingmanager-data.service';
import { EditApartementDialogComponent } from '../../dialogs/edit-apartement-dialog/edit-apartement-dialog.component';
import { ApartementData, ApartementDataService } from '../../services/apartement-data.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-apartementlist',
  templateUrl: './apartementlist.component.html',
  styleUrls: ['./apartementlist.component.css']
})
export class ApartementlistComponent implements OnInit, OnChanges {
  @Input()
  realestate: RealestateData;

  apartementList = null;
  displayedColumns = ['identifier', 'skvIdentifier', 'level', 'rok', 'area', 'edit'];

  constructor(
    private apartementService: ApartementDataService,
    private dialog: MatDialog,
    private navService: NavService,

  ) {
  }

  ngOnInit(): void {
   // this.navService.hideDetailNav();
    this.apartementList = new MatTableDataSource();

    this.apartementService.getApartementsForRealestate(this.realestate).subscribe((data: ApartementData[]) => { this.apartementList.data = data });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("--------------->>> Input data changed");
    console.log(JSON.stringify(changes));

    this.realestate = changes.realestate.currentValue;

    this.apartementService.getApartementsForRealestate(this.realestate).subscribe((data: ApartementData[]) => { this.apartementList = data });
    /*
        if (changes.myFirstInputParameter && changes.myFirstInputParameter.currentValue) {

        }
      */
  }


  editApartementDialog(apartement: ApartementData) {
    let createNewEntrance = false;

    if (apartement == null) {
      console.log("CREATING NEW");
      createNewEntrance = true;
      apartement = this.apartementService.getEmptyApartementObject();
    }

    let dialogData = { apartement: apartement, realestate: this.realestate };
    console.log("dialogData: " + JSON.stringify(dialogData));


    this.dialog.open(EditApartementDialogComponent, { data: dialogData }).afterClosed().subscribe(
      tData => {
        console.log("Dialog output:", tData);
        if (createNewEntrance) {
          this.apartementService.create(this.realestate, tData).subscribe(res => {
            console.log("create ok");
            this.apartementService.getApartementsForRealestate(this.realestate).subscribe((data: ApartementData[]) => { this.apartementList = data });

          },
            err => {
              console.log("create fail " + err); 4
            });
        } else {
          this.apartementService.update(this.realestate.id, apartement.id, tData).subscribe(res => {
            console.log("update ok");
            this.apartementService.getApartementsForRealestate(this.realestate).subscribe((data: ApartementData[]) => { this.apartementList = data });
          },
            err => {
              console.log("update fail " + err); 4
            });
        }

      });
  }

}
