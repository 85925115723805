import { RealestateData } from 'src/app/buildingmanager/services/buildingmanager-data.service';
import { NavService } from './../nav/services/nav.service';
import { AfterViewInit, ViewChild, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BuildingData } from '../buildingmanager/services/building-data.service';
import { BuildingmanagerDataService } from '../buildingmanager/services/buildingmanager-data.service';
import { EntranceData, EntranceDataService } from '../buildingmanager/services/entrance-data.service';
import { MunicipalityData, MunicipalityDataService } from '../buildingmanager/services/municipality-data.service';
import { EditApartementDialogComponent } from './dialogs/edit-apartement-dialog/edit-apartement-dialog.component';
import { ApartementData, ApartementDataService } from './services/apartement-data.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-apartementmanager',
  templateUrl: './apartementmanager.component.html',
  styleUrls: ['./apartementmanager.component.css']
})
export class ApartementmanagerComponent implements OnInit, AfterViewInit{

  municipalityList = [];
  realestatesList = [];
  entranceList = [];
  apartementList = null;

  activeApartement = null;

  initialSelection = [];
  allowMultiSelect = true;
  selection: SelectionModel<ApartementData>;

  displayedColumns: string[] = ['checked', 'hyresobjekt', 'fastighet', 'skvIdentifier', 'rok', 'area'];

  constructor( private dialog: MatDialog,
               private municipalityService: MunicipalityDataService,
               private managerService: BuildingmanagerDataService,
               private apartementService: ApartementDataService,
               private navService: NavService,
               private changeDetector: ChangeDetectorRef,
               private route: Router,
               ) {
      this.selection = new SelectionModel<ApartementData>(this.allowMultiSelect, this.initialSelection);
   }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    console.log("!!!!! ApartementManager:ngOnInit()")
    this.navService.hideDetailNav();

    this.apartementList = new MatTableDataSource();

    this.municipalityService.getAll().subscribe((data: MunicipalityData[])=> {
      this.municipalityList = data
    });

    this.apartementService.getApartementsListGroupedByRealestate().subscribe((data: any[])=>{
      this.realestatesList = data;
      let tApartementsList = [];
      console.log(this.realestatesList);

      this.realestatesList.forEach( (realestate) => {
        realestate.apartements.forEach( (apartement) => {
          apartement['realestate'] = realestate;
          tApartementsList.push(apartement);
        });
      });

      this.apartementList.data = tApartementsList;
      console.log(this.apartementList.data);
      this.changeDetector.markForCheck();
    });
  }


  ngAfterViewInit() {
    this.apartementList.sort = this.sort;
  }


  showApartementDetail(apartement: ApartementData): void {
    this.activeApartement = apartement;
    this.route.navigate(['', { outlets: { detailOutlet: ['buildingdetail', apartement.id] } }]);
  }


  toggleSelect(apartement: ApartementData) {
    this.selection.toggle(apartement);

    console.log("toggleSelect()" + this.selection.selected.length);

    if (this.selection.selected.length == 1) {
      console.log("update detail nav");
      this.navService.showDetailNav();
      this.showApartementDetail(this.selection.selected[0]);
    }
    else {
      this.navService.hideDetailNav();
    }
  }

  masterToggle(){

  }

  isAllSelected() {

  }

  newApartementDialog(realestate: RealestateData, entrancelist: EntranceDataService[]): void {
    this.dialog.open(EditApartementDialogComponent).afterClosed().subscribe(
      data => {
        /*
        console.log("Dialog output:", data);
        this.apartementService.create(data).subscribe(res=>{
          console.log("create ok");
          this.managerService.getRealestateList().subscribe((data: any[])=>{
            this.realestatesList = data;
          });
        },
        err => {
          console.log("create fail " + err );
        });
        */
    });
  }

  //a function that creates a dialog for editing an apartement and then updates the apartement list
  editApartementDialog(apartement: ApartementData, realestate: RealestateData, entrancelist: EntranceDataService[]): void {
    this.dialog.open(EditApartementDialogComponent, {data: {apartement: apartement, realestate: realestate, entrancelist: entrancelist}}).afterClosed().subscribe(
      data => {
        console.log("Dialog output:", data);
        this.apartementService.update(realestate.id, apartement.id, data).subscribe(res=>{
          console.log("update ok");
          this.managerService.getRealestateList().subscribe((data: any[])=>{
            this.realestatesList = data;
          });
        },
        err => {
          console.log("update fail " + err );
        });
    });
  }


}
