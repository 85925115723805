<h1 mat-dialog-title>Redigera Byggnad</h1>
<div mat-dialog-content [formGroup]="form" fxLayout='column'>

	<div fxLayout="row">
		<mat-form-field>
			<mat-label>Namn</mat-label>
			<input matInput formControlName="name">
		</mat-form-field>
	</div>
	<div fxLayout="row">
		<mat-form-field>
			<mat-label>Byggnadsarea (BYA)</mat-label>
			<input matInput formControlName="area_foundation">
		</mat-form-field>
	</div>
	<div fxLayout="row">
		<mat-label>Finns Skyddsrum</mat-label>
		<mat-checkbox formControlName="shelter"></mat-checkbox>
	</div>

	<mat-divider></mat-divider>

</div>
<div mat-dialog-actions>
	<button mat-button (click)="onCancelClick()">Avbryt</button>
	<button mat-button (click)="onSaveClick()">Spara</button>
</div>
