<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
  <mat-card-title>Mina ärenden</mat-card-title>
  <mat-card-content fxLayout="column">
    <div fxLayout="row">
      <button mat-stroked-button color="primary" (click)="editTaskDialog(null)" class="app-buttonsize-150"><mat-icon class="size-64" inline="true">add_task</mat-icon> Ny
        Uppgift</button>
    </div>

  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
  <mat-card-title></mat-card-title>
  <mat-card-content fxLayout="column">
    <app-tasklisttable listtype="userAssigned" [tasklistDataSource]="myTasksDataSource"></app-tasklisttable>

  </mat-card-content>
</mat-card>
