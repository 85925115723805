<h1 mat-dialog-title>{{dialogTitle}}</h1>
<div mat-dialog-content [formGroup]="form" fxLayout='column'>
  <mat-form-field fxFill>
    <mat-label>Anteckning</mat-label>
    <textarea matInput formControlName="entry" md-maxlength="250" rows="2"></textarea>
  </mat-form-field>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="card-padding-16">
  <button mat-stroked-button (click)="cancelDialog()">Avbryt</button>
  <button mat-stroked-button color="primary" (click)="saveDialog()" [disabled]="form.invalid">Spara</button>
</div>
