<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
	<mat-card-header>
		<mat-card-title>Intresseanmälan</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div fxLayout="row">
			<button mat-button (click)="newInterestDialog(null)">Ny Intresseanmälan</button>
		</div>
		<div fxLayout="column">
			<mat-divider></mat-divider>
			<table mat-table [dataSource]="interestList" fxFlex>

				<ng-container matColumnDef="checked">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let interest">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="$event ? toggleSelect(interest) : null"
							[checked]="selection.isSelected(interest)">
						</mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Namn</th>
					<td mat-cell *matCellDef="let interest">{{interest.name}}</td>
				</ng-container>
				<ng-container matColumnDef="ssn">
					<th mat-header-cell *matHeaderCellDef>Kontakt</th>
					<td mat-cell *matCellDef="let interest">{{interest.email}} / {{interest.phone}}</td>
				</ng-container>
				<ng-container matColumnDef="phone">
					<th mat-header-cell *matHeaderCellDef>Telefonnummer</th>
					<td mat-cell *matCellDef="let interest">{{interest.phone}}</td>
				</ng-container>
				<ng-container matColumnDef="checkedState">
					<th mat-header-cell *matHeaderCellDef>Kontrollerad</th>
					<td mat-cell *matCellDef="let interest">{{interest.marksCheckedStatus}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let interest; columns: displayedColumns"></tr>
			</table>
		</div>
	</mat-card-content>
</mat-card>