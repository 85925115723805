import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar : MatSnackBar) {

  }


  public actionMessage(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  public errorMessage(message: string) {
    this.snackBar.open(message, "Error");
  }

  public message(message: string) {
    this.snackBar.open(message, null, {duration: 2000,});
  }

}
