import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Owner } from '../../services/owner-data.service';


@Component({
  selector: 'app-editowner-dialog',
  templateUrl: './editowner-dialog.component.html',
  styleUrls: ['./editowner-dialog.component.css']
})
export class EditownerDialogComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditownerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Owner
  ) {
    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(),
      shorthand: new UntypedFormControl(),
   });
  }

  ngOnInit(): void {
  }

  onCancelClick(){
    console.log("Close and discard Realesteate dialog");
    this.dialogRef.close();
  }


  onSaveClick(){
    console.log("Save Realesteate dialog");
    this.dialogRef.close(this.form.value);
  }

}
