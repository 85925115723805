import { TasklistDataService } from './../../services/tasklist-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { MatCard } from '@angular/material/card';

import { Tasklist } from '../../services/tasklist-data.service';
import { EditTasklistDialogComponent } from '../../dialogs/edittasklist-dialog/edittasklist-dialog.component';
import { Personnel, PersonnelDataService } from 'src/app/personnellist/personnel-data.service';


@Component({
  selector: 'app-tasklist-settings',
  templateUrl: './tasklist-settings.component.html',
  styleUrls: ['./tasklist-settings.component.css']
})
export class TasklistSettingsComponent implements OnInit {

  tasklistList = Array<Tasklist>();
  personnelList = Array<Personnel>();

  displayedColumns = ['name', 'edit', 'owner'];

  constructor(
    private dialog: MatDialog,
    private tasklistService: TasklistDataService,
    private personnelService: PersonnelDataService,
  ) { }

  ngOnInit(): void {
    this.refreshTasklistList();

    this.personnelService.getAll().subscribe((data: any[]) => {
      this.personnelList = data;
    });
  }

  public refreshTasklistList(){
    this.tasklistService.getAll().subscribe((data: any[]) => {
      this.tasklistList = data;
    });
  }

  public getNameForTasklistOwner(tasklist){
    return this.tasklistService.getNameForTasklistOwner(tasklist, this.tasklistList);
  }

  public editTasklistDialog(tasklist: Tasklist){
    let createNewTasklist = false;

    if (tasklist == null) {
      createNewTasklist = true;
      tasklist = this.tasklistService.getEmptyTasklistObject();
    }

    this.dialog.open(EditTasklistDialogComponent, { data: tasklist, width: '400px' }).afterClosed().subscribe(
      data => {
        if (createNewTasklist) {
          this.tasklistService.createTaskList(data).subscribe(res => {
            console.log("create ok");
            this.refreshTasklistList();
          },
            err => {
              console.log("create fail " + err);
            });
        }
        else {
          this.tasklistService.update(tasklist.id, data).subscribe(res => {
            console.log("update ok");
            this.refreshTasklistList();
          },
            err => {
              console.log("update fail " + err);
            });
        }
      });
  }

}
