import { Personnel } from 'src/app/personnellist/personnel-data.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
	providedIn: 'root'
})
export class TasklistDataService {

  public static readonly TASK_CHANGE_EVENT = "TASK_CHANGE_EVENT";
  public static readonly TASKLIST_CHANGE_EVENT = "TASKLIST_CHANGE_EVENT";

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/tasklists/";

  private eventEmitter: EventEmitter<string>;

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(
		private httpClient: HttpClient,
	) {
    this.eventEmitter = new EventEmitter<string>();
	}

  getChangeEmitter(): EventEmitter<string> {
    return this.eventEmitter;
  }

  emitChangeEvent(change: string) {
    console.log("TasklistDataService.emitChangeEvent() change: " + change);
    this.eventEmitter.emit(change);
  }

	createTaskList(taskList): Observable<Tasklist> {
		return this.httpClient.post<Tasklist>(this.REST_API_SERVER + 'tasklists/', JSON.stringify(taskList), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	getById(id): Observable<Tasklist> {
		return this.httpClient.get<Tasklist>(this.REST_API_SERVER + 'tasklists/' + id)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	getAll(): Observable<Tasklist[]> {
		return this.httpClient.get<Tasklist[]>(this.REST_API_SERVER + 'tasklists/')
			.pipe(
				catchError(this.errorHandler)
			)
	}

	update(id, tasklist): Observable<Tasklist> {
		return this.httpClient.put<Tasklist>(this.REST_API_SERVER + 'tasklists/' + id + '/', JSON.stringify(tasklist), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	delete(id) {
		return this.httpClient.delete<Tasklist>(this.REST_API_SERVER + 'tasklists/' + id, this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}


  //get all tasks visible to the current user, including tasks assigned to other users
  getAllTasksVisible(): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.REST_API_SERVER + 'tasks/')
      .pipe(
        catchError(this.errorHandler)
      )
  }


  //Get all tasks assigned to the current user
  getAllTasksForCurrentUser(): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.REST_API_SERVER + 'usertasks/')
      .pipe(
        catchError(this.errorHandler)
      )
  }

  //get a task by id
  getTaskById(id): Observable<Task> {
    return this.httpClient.get<Task>(this.REST_API_SERVER + 'tasks/' + id + '/')
      .pipe(
        catchError(this.errorHandler)
      )
  }

  //Create a new task in a tasklist
  createNewTask(task): Observable<Task> {
    return this.httpClient.post<Task>(this.REST_API_SERVER + 'tasks/', JSON.stringify(task), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  //Update a task in a tasklist
  updateTask(id, task): Observable<Task> {
    return this.httpClient.put<Task>(this.REST_API_SERVER + 'tasks/' + id + '/', JSON.stringify(task), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

	errorHandler(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Personnel-data.service: Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}

  public getNameForTasklistOwner(tasklist: Tasklist, tasklistList: Tasklist[]) {
		let ownerName = "HITTAR INTE";
		let tTasklist:Tasklist = tasklistList.find(tasklist => tasklist.owner.id == tasklist.id);

		if (tTasklist) {
			ownerName = tTasklist.owner.first_name + " " + tTasklist.owner.last_name;
		}

		return ownerName
	}


	public getEmptyTasklistObject(): Tasklist {
		return {
			id: null,
			name: '',
			owner: null,
			members: [],
			send_notification: false,
		}
	}

  public getEmptyTaskObject(): Task {
    return {
      id: null,
      title: '',
      description: '',
      tasklist: null,
      assigned_to: null,
      due_date: null,
      completed: false,
      contact_phone: '',
      text_location: '',
      priority: 0,
      foreign_id: "",
      internal_id: "",
      master_key: false,
      completed_date: null,
      completed_by: null,
      completeby_date: null,
      created_date: null,
      progresslog: null,
      created_by: null,
    }
  }

}

export interface Tasklist {
	id: number;
	name: string;
	owner: Personnel;
	members: Personnel[];
	send_notification: boolean;
}

export interface Task {
  id: number;
  title: string;
  description: string;
  foreign_id: string;
  internal_id: string;
  tasklist: Tasklist;
  assigned_to: Personnel;
  due_date: Date;
  created_date: Date;
  contact_phone: string;
  text_location: string;
  priority: number;
  master_key: boolean;
  completed: boolean;
  completed_date: Date;
  completed_by: Personnel;
  completeby_date: Date;
  progresslog: number;
  created_by: Personnel;
}

