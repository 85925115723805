import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


import { Personnel, PersonnelDataService } from 'src/app/personnellist/personnel-data.service';

@Component({
  selector: 'app-cange-password-dialog',
  standalone: true,
  imports: [MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule],
  templateUrl: './change-password-dialog.component.html',
  styleUrl: './change-password-dialog.component.css'
})
export class ChangePasswordDialogComponent {
  form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private dialog: MatDialog,
    private pdService: PersonnelDataService,
    @Inject(MAT_DIALOG_DATA) public data: Personnel
  ) {
    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      password: new UntypedFormControl(""),
      confirm_password: new UntypedFormControl(""),
    });
  }

  ngOnInit(): void {
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  saveDialog() {
    this.dialogRef.close(this.form.value);
  }
}
