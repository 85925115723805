import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BuildingData } from './building-data.service';
import { RealestateData } from './buildingmanager-data.service';

@Injectable({
	providedIn: 'root'
})
export class EntranceDataService {

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/realestates/";


	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,) {

	}

	create(realestate: RealestateData, building: BuildingData, entrance: EntranceData): Observable<EntranceData> {
		console.log("creating entrance : " + JSON.stringify(entrance));
		return this.httpClient.post<EntranceData>(this.REST_API_SERVER + realestate.id +
			'/buildings/' + building.id +
			'/entrances/', JSON.stringify(entrance), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	update(realestate: RealestateData, building: BuildingData, id, entrance: EntranceData): Observable<EntranceData> {
    console.log("updating entrance : " + JSON.stringify(entrance));
		return this.httpClient.put<EntranceData>(this.REST_API_SERVER + realestate.id +
			'/buildings/' + building.id +
			'/entrances/' + id + "/", JSON.stringify(entrance), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	delete(realestate: RealestateData, building: BuildingData, entrance: EntranceData){
		return this.httpClient.delete<EntranceData>(this.REST_API_SERVER + realestate.id +
			'/buildings/' + building.id +
			'/entrances/' + entrance.id + "/", this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}


	errorHandler(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}

	public getEmptyEntranceObject(): EntranceData {
		return {
			id: null,
			city: '',
			number: '',
			streetname: '',
			zipcode: ''
		}
	}
}

export interface EntranceData {
	id: number;
	city: string;
	number: string;
	streetname: string;
	zipcode: string;
}
