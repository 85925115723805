<mat-card appearance="outlined" fxFill>
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row">
        <h2>Person</h2>
        <div>&nbsp;</div><a [routerLink]="" (click)="closeDetailNav()"><mat-icon>close</mat-icon></a>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="mat-headline-6">{{personnel.first_name}} {{personnel.last_name}}</div>
    <mat-divider></mat-divider>
    <div fxLayout="row">
      <div fxLayout="column" fxLayoutGap="1em" fxFlex="50">
        <div>
          <div class="mat-subtitle-2">Förnamn</div>
          {{personnel.first_name}}
        </div>
        <div>
          <div class="mat-subtitle-2">Efternamn</div>
          {{personnel.last_name}}
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="1em" fxFlex="50">
        <div>
          <div class="mat-subtitle-2">e-post</div>
          {{personnel.email}}
        </div>
        <div>
          <div class="mat-subtitle-2">Användarnamn</div>
          {{personnel.username}}
        </div>
        <div>
          <div class="mat-subtitle-2">Telefon 1</div>
          {{personnel.phone_number_1}}
        </div>
        <div>
          <div class="mat-subtitle-2">Telefon 2</div>
          {{personnel.phone_number_2}}
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="1em">
      <div>
        <div class="mat-headline-6">Roller</div>
        <mat-divider></mat-divider>
        <mat-slide-toggle [(ngModel)]="personnel.role_administration">
          Hyresadministratör
        </mat-slide-toggle>
      </div>
      <div>
        <mat-slide-toggle [(ngModel)]="personnel.role_maintenance">
          Vaktmästare
        </mat-slide-toggle>
      </div>
      <button mat-button (click)="saveRoles()">Spara</button>
    </div>
    <div fxLayout="column" fxLayoutGap="1em">
      <div>
        <div class="mat-headline-6">Lösenord</div>
        <mat-divider></mat-divider>
      </div>
      <button mat-button (click)="setNewPassword()">Byt lösenord</button>
    </div>
  </mat-card-content>
</mat-card>
