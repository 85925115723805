import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Tasklist, Task, TasklistDataService } from '../../services/tasklist-data.service';
import { Personnel, PersonnelDataService } from 'src/app/personnellist/personnel-data.service';

@Component({
  selector: 'app-edittask-dialog',
  templateUrl: './edittask-dialog.component.html',
  styleUrls: ['./edittask-dialog.component.css']
})
export class EdittaskDialogComponent {
  form: UntypedFormGroup;
  personnelList = [];
  readonly = false;
  task_lists = [];
  priority_list =[];

  constructor(
    private dialogRef: MatDialogRef<EdittaskDialogComponent>,
    private dialog: MatDialog,
    private tasklistService: TasklistDataService,
    private personnelService: PersonnelDataService,
    @Inject(MAT_DIALOG_DATA) public data: Task
  ) {

    this.priority_list = [{level: 1, name: 'Låg'}, {level: 2, name: 'Medium'}, {level: 3, name: 'Hög'}];

    dialogRef.disableClose = true;

    console.log(JSON.stringify(data));

    this.form = new UntypedFormGroup({
      tasklist: new UntypedFormControl(data.tasklist),
      title: new UntypedFormControl(data.title),
      foreign_id: new UntypedFormControl(data.foreign_id),
      internal_id: new UntypedFormControl(data.internal_id),
      description: new UntypedFormControl(data.description),
      text_location: new UntypedFormControl(data.text_location),
      contact_phone: new UntypedFormControl(data.contact_phone),
      assigned_to: new UntypedFormControl(data.assigned_to),
      priority: new UntypedFormControl(data.priority),
      master_key: new UntypedFormControl(data.master_key),
      completed: new UntypedFormControl(data.completed),
      due_date: new UntypedFormControl(data.due_date),
    });
  }

  ngOnInit(): void {
    this.personnelService.getAll().subscribe((data: Personnel[]) => { this.personnelList = data });
    this.tasklistService.getAll().subscribe((data: Tasklist[]) => { this.task_lists = data });
  }

  cancelDialog(){
    this.dialogRef.close();
  }

  saveDialog(){
    this.dialogRef.close(this.form.value);
  }

}
