import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { BuildingData } from '../../services/building-data.service';


@Component({
  selector: 'app-editbuilding-dialog',
  templateUrl: './editbuilding-dialog.component.html',
  styleUrls: ['./editbuilding-dialog.component.css']
})
export class EditbuildingDialogComponent implements OnInit {

  form: UntypedFormGroup;
  
  constructor(
    public dialogRef: MatDialogRef<EditbuildingDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: BuildingData
  ) { 
    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(),
      area_foundation: new UntypedFormControl(),
   });
  }

  ngOnInit(): void {
  }

  onCancelClick(){
    console.log("Close and discard Realesteate dialog");
    this.dialogRef.close();
  }


  onSaveClick(){
    console.log("Save building dialog");
    this.dialogRef.close(this.form.value);
  }
}
