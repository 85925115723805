import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class InterestDataService {

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/";


	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient) { }

	public getEmptyInterestObject(): InterestData {
		return {
			id: null,
			name: '',
			ssn: '',
			email: '',
			phone: '',

			currentLandlord: '',
			currentStreet: '',
			currentAreacode: '',
			currentCity: '',

			employer: '',
			employmentContact: '',
			employmentStartdate: '',
			employmentType: '',
			employmentYearly: '',

			comment: '',

			apartementRok: '',
			apartementAdults: '',
			apartementChildren: '',

			marksCheckedStatus: '',
			marksLastUpdatedBy: '',
		}
	}

	public getAll(): Observable<InterestData[]> {
		return this.httpClient.get<InterestData[]>(this.REST_API_SERVER + 'interests')
			.pipe(
				catchError(this.errorHandler)
			)
	}

	getById(id: number): Observable<InterestData> {
		return this.httpClient.get<InterestData>(this.REST_API_SERVER + 'interests/' + id)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	public create(data): Observable<InterestData> {
		return this.httpClient.post<InterestData>(this.REST_API_SERVER + 'interests/', JSON.stringify(data), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}


	errorHandler(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}
}

export interface InterestData {
	id: number;
	name: string;
	ssn: string;
	email: string;
	phone: string;

	currentLandlord: string;
	currentStreet: string;
	currentAreacode: string;
	currentCity: string;

	employer: string;
	employmentContact: string;
	employmentStartdate: string;
	employmentType: string;
	employmentYearly: string;

	comment: string;

	apartementRok: string;
	apartementAdults: string;
	apartementChildren: string;

	marksCheckedStatus: string;
	marksLastUpdatedBy: string;
}
