import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService
	) {
		console.log("AuthGuard.constructor() called");
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const user = this.authenticationService.userValue;
		//console.log(JSON.stringify(user));

		if (user) {
			console.log("->> user is authenticated, returning true");
			// logged in so return true
			return true;
		} else {
			// not logged in so redirect to login page with the return url
			console.log("->> ! user is not authenticated, redirecting to /login");
			this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
			return false;
		}
	}
}
