<div fxLayout="column">
	<table mat-table [dataSource]="apartementList" matSort fxFlex>
		<ng-container matColumnDef="identifier">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Id nummer</th>
			<td mat-cell *matCellDef="let apartement">{{apartement.identifier}}
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let apartement"><button mat-icon-button [matMenuTriggerFor]="editMenu"
					[matMenuTriggerData]="{data: apartement}">
					<mat-icon>more_vert</mat-icon>
				</button></td>
		</ng-container>
		<ng-container matColumnDef="skvIdentifier">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>SKV nummer</th>
			<td mat-cell *matCellDef="let apartement">{{ apartement.skvIdentifier }}
			</td>
		</ng-container>

    <ng-container matColumnDef="rok">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>RoK</th>
			<td mat-cell *matCellDef="let apartement">{{ apartement.rok }}
			</td>
		</ng-container>

		<ng-container matColumnDef="area">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Area</th>
			<td mat-cell *matCellDef="let apartement">{{ apartement.area }}
			</td>
		</ng-container>

		<ng-container matColumnDef="level">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Våning</th>
			<td mat-cell *matCellDef="let apartement">{{ apartement.level }}
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let realestate; columns: displayedColumns">
		</tr>

	</table>
	<mat-action-row>
		<button mat-button color="primary" (click)="editApartementDialog(null)">Ny Lägenhet</button>
	</mat-action-row>

</div>

<mat-menu matMenuContent #editMenu="matMenu">
	<ng-template matMenuContent let-data="data" align="end">
		<button mat-menu-item (click)="editApartementDialog(data)">Redigera</button>
		<button mat-menu-item>Radera</button>
	</ng-template>
</mat-menu>
