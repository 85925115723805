import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthenticationService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add auth header with jwt if user is logged in and request is to the api url
		const currentUser = this.authenticationService.userValue;
		const isLoggedIn = currentUser && currentUser.access;
		//const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isApiUrl = request.url.startsWith(this.authenticationService.REST_API_SERVER);

		if (isLoggedIn && isApiUrl) {
			//console.log("->> Adding authorization header to request to " + request.url);
			let authString = "Bearer " + currentUser.access;
			request = request.clone({
				setHeaders: {
					Authorization: authString,
				}
			});
		}

		return next.handle(request);
	}
}
