<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
  <mat-card-title>Översikt alla ärenden</mat-card-title>
  <mat-card-content fxLayout="column">
    <div fxLayout="row">
      <button mat-stroked-button color="primary" (click)="editTaskDialog(null)" class="app-buttonsize-150"><mat-icon
          class="size-64" inline="true">add_task</mat-icon> Ny
        Uppgift</button>
      <span fxFlex></span>
      <form fxLayoutGap="1em">
        <mat-form-field fxFlex="400px">
          <mat-label>Urval</mat-label>
          <mat-select placeholder="Välj ärendelista" (selectionChange)="onTaskListChange($event)">
            <mat-option *ngFor="let tasklist of filterTasklist" [value]="tasklist">
              {{tasklist.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
  <mat-card-title>Öppna ärenden</mat-card-title>
  <mat-card-content fxLayout="column">
    <app-tasklisttable [tasklistDataSource]=openTasksDataSource></app-tasklisttable>

  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
  <mat-card-title>Avslutade ärenden</mat-card-title>
  <mat-card-content fxLayout="column">
    <app-tasklisttable [tasklistDataSource]=closedTasksDataSource></app-tasklisttable>

  </mat-card-content>
</mat-card>
