import { Component } from '@angular/core';
import { Task, Tasklist, TasklistDataService } from '../../services/tasklist-data.service';
import { MatDialog } from '@angular/material/dialog';
import { EdittaskDialogComponent } from '../../dialogs/edittask-dialog/edittask-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-tasklistall',
  templateUrl: './tasklistall.component.html',
  styleUrls: ['./tasklistall.component.css']
})
export class TasklistallComponent {
  tasklistList = Array<Tasklist>();
  taskList = Array<Task>();
  rawTasklist = Array<Task>();
  filterTasklist = Array<Tasklist>();
  openTasks = Array<Task>();
  closedTasks = Array<Task>();
  selectedFilterListId = -1;

  openTasksDataSource = new MatTableDataSource<Task>();
  closedTasksDataSource = new MatTableDataSource<Task>();

  constructor(
    private dialog: MatDialog,
    private tasklistDataService: TasklistDataService) {
  }

  ngOnInit(): void {
    this.refreshTasklist();
  }


  refreshTasklist(): void {
    this.tasklistDataService.getAll().subscribe((data: any[]) => {
      this.tasklistList = data;

      this.filterTasklist.length = 0;
      this.filterTasklist.push({ id: -1, name: "Alla listor", owner: null, members: [], send_notification: false });
      for (let tasklist of this.tasklistList) {
        this.filterTasklist.push(tasklist);
      }
    });

    this.tasklistDataService.getAllTasksVisible().subscribe((data: any[]) => {
      this.rawTasklist = data;
      //this.closedTasksDataSource.data = this.taskList;
      this.sortTasks();
    });

  }

  sortTasks(): void {
    console.log("--> sortTasks() sorting");

    this.taskList.length = 0;

    for (let task of this.rawTasklist) {
      console.log(this.selectedFilterListId + " == " + task.tasklist);
      if (this.selectedFilterListId == -1 ||  (task.tasklist as any) == this.selectedFilterListId) {
        this.taskList.push(task);
      }
    }

    this.openTasks.length = 0;
    this.closedTasks.length = 0;

    for (let task of this.taskList) {
      if (task.completed){
        this.closedTasks.push(task);
      } else if (!task.completed) {
        this.openTasks.push(task);
      } else {
        console.log("DID NOT MATCH : sortTasks() task: " + JSON.stringify(task));
      }
    }

    this.openTasksDataSource.data = this.openTasks;
    this.closedTasksDataSource.data = this.closedTasks;

//    console.log("myTasksAssignedToOthers: " + JSON.stringify(this.myTasksAssignedToOthers));
//    console.log("myAssignedTasks: " + JSON.stringify(this.myAssignedTasks));

  }

  filterTasksByTasklist(selectedTasklist: any): any[] {
    return this.taskList.filter(task => task.tasklist === selectedTasklist.id);
  }

  onTaskListChange(event): void {
    //console.log("onTaskListChange() event: " + JSON.stringify(event));
    this.selectedFilterListId = event.value.id;
    this.sortTasks();
  }


  editTaskDialog(task: Task): void {
    let createNewTask = false;

    if (task == null) {
      createNewTask = true;
      task = this.tasklistDataService.getEmptyTaskObject();
    }

    console.log("task: '" + task + "' createNewTask: '" + createNewTask + "'");
    console.log(JSON.stringify(task));

    this.dialog.open(EdittaskDialogComponent, { data: task, width: '500px' }).afterClosed().subscribe(
      data => {
        if (createNewTask) {
          this.tasklistDataService.createNewTask(data).subscribe(res => {
            console.log("create ok");
            this.refreshTasklist();
          },
            err => {
              console.log("create fail " + err);
            });
        }
        else {
          this.tasklistDataService.updateTask(task.id, data).subscribe(res => {
            console.log("update ok");
            this.refreshTasklist();
          },
            err => {
              console.log("update fail " + err);
            });
        }
      });
  }
}
