<div>
  <div fxLayout="row">
    <button mat-stroked-button color="primary" (click)="addEntryDialog(null)" class="card-padding-16">Ny anteckning</button>
  </div>

  <mat-card *ngFor="let entry of progresslog.entries" appearance="outlined" class="mat-elevation-z2 card-padding-16">
    <mat-card-content fxLayout="column">
      {{ entry.entry }}
    </mat-card-content>
    <mat-card-footer fxFlexAlign="end">
      {{ entry.created_date| date: 'yyyy-MM-dd HH:mm'}} av  {{ entry.created_by }}
    </mat-card-footer>
  </mat-card>
</div>
