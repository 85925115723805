import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { BuildingmanagerDataService, RealestateData } from './services/buildingmanager-data.service';
import { EditownerDialogComponent } from './dialogs/editowner-dialog/editowner-dialog.component';
import { EditrealestateDialogComponent } from './dialogs/editrealestate-dialog/editrealestate-dialog.component'
import { MunicipalityDataService, MunicipalityData } from './services/municipality-data.service';

import { Owner, OwnerDataService } from './services/owner-data.service';
import { EditbuildingDialogComponent } from './dialogs/editbuilding-dialog/editbuilding-dialog.component';
import { BuildingData, BuildingDataService } from './services/building-data.service';
import { EditentranceDialogComponent } from './dialogs/editentrance-dialog/editentrance-dialog.component';
import { EntranceDataService } from './services/entrance-data.service';

import { MapComponent } from '../map/map.component';
import { SelectionModel } from '@angular/cdk/collections';
import { NavService } from '../nav/services/nav.service';


@Component({
  selector: 'app-buildingmanager',
  templateUrl: './buildingmanager.component.html',
  styleUrls: ['./buildingmanager.component.css']
})
export class BuildingmanagerComponent implements OnInit, OnDestroy {

  realestatesList = [];
  municipalityList = [];
  ownerList = []
  activeRealestate = null;

  initialSelection = [];
  allowMultiSelect = true;
  selection: SelectionModel<RealestateData>;

  displayedColumns: string[] = ['checked', 'name', 'edit', 'municipality', 'owner'];


  constructor(private managerService: BuildingmanagerDataService,
    private municipalityService: MunicipalityDataService,
    private buildingService: BuildingDataService,
    private ownerService: OwnerDataService,
    private entranceService: EntranceDataService,
    private dialog: MatDialog,
    private route: Router,
    private navService: NavService,
  ) {
  }

  ngOnInit() {
    this.navService.hideDetailNav();

    this.municipalityService.getAll().subscribe((data: MunicipalityData[]) => { this.municipalityList = data });
    this.managerService.getRealestateList().subscribe((data: any[]) => { this.realestatesList = data; });
    this.ownerService.getAll().subscribe((data: Owner[]) => { this.ownerList = data });

    this.selection = new SelectionModel<RealestateData>(this.allowMultiSelect, this.initialSelection);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.navService.hideDetailNav();
  }

  getMunicipalityName(id: number): string {
    for (let municipality of this.municipalityList) {
      if (municipality.id == id) {
        return municipality.name;
      }
    }

    return "Undefined";
  }


  getOwnerName(id: number): string {
    let owner = this.ownerList.find(x => x.id == id);

    if (owner) {
      return owner.name;
    }

    return "Undefined";
  }


  isActiveRealestate(realestate: RealestateData): boolean {
    if (this.activeRealestate == realestate) {
      return true;
    }

    return false;
  }

  showRealestateDetail(realestate: RealestateData): void {
    this.activeRealestate = realestate;
    this.route.navigate(['', { outlets: { detailOutlet: ['buildingdetail', realestate.id] } }]);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.realestatesList.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.realestatesList.forEach(row => this.selection.select(row));
  }

  toggleSelect(realestate: RealestateData) {
    this.selection.toggle(realestate);

    console.log("toggleSelect()" + this.selection.selected.length);

    if (this.selection.selected.length == 1) {
      this.navService.showDetailNav();
      this.showRealestateDetail(this.selection.selected[0]);
    }
    else {
      this.navService.hideDetailNav();
    }
  }


  editRealestateDialog(realestate: RealestateData): void {
    let createNewRealestate = false;

    if (realestate == null) {
      createNewRealestate = true
      realestate = this.managerService.getEmptyRealestateObject();
    }

    console.log("create new : " + createNewRealestate);

    this.dialog.open(EditrealestateDialogComponent, { data: realestate }).afterClosed().subscribe(
      data => {
        console.log("Dialog output:", data);

        if (createNewRealestate) {
          this.managerService.create(data).subscribe(res => {
            console.log("create ok");
            this.managerService.getRealestateList().subscribe((data: any[]) => {
              this.realestatesList = data;
            });
          },
            err => {
              console.log("create failed " + err);
            });

        } else {
          this.managerService.update(realestate.id, data).subscribe(res => {
            console.log("update ok");
            this.managerService.getRealestateList().subscribe((data: any[]) => {
              this.realestatesList = data;
            });
          },
            err => {
              console.log("update failed " + err);
            });
        }
      });
  }

  newBuildingDialog(realestate: RealestateData) {
    this.dialog.open(EditbuildingDialogComponent).afterClosed().subscribe(
      data => {
        console.log("Dialog output:", data);
        this.buildingService.create(realestate, data).subscribe(res => {
          console.log("create ok");
          this.managerService.getRealestateList().subscribe((data: any[]) => { this.realestatesList = data; });
        },
          err => {
            console.log("create fail " + err);
          });
      });
  }


  realestateDialogSave() {
  }

  realestateDialogClose() {
  }


  newOwnerDialog() {
    this.dialog.open(EditownerDialogComponent).afterClosed().subscribe(
      data => {
        console.log("Dialog output:", data);
        this.ownerService.create(data).subscribe(res => {
          console.log("create ok");
          this.ownerService.getAll().subscribe((data: Owner[]) => { this.municipalityList = data });
        },
          err => {
            console.log("create fail " + err);
          });
      })
  }


  newEntranceDialog(realestate: RealestateData, building: BuildingData) {
    this.dialog.open(EditentranceDialogComponent).afterClosed().subscribe(
      data => {
        console.log("Dialog output:", data);
        this.entranceService.create(realestate, building, data).subscribe(res => {
          console.log("create ok");
        },
          err => {
            console.log("create fail " + err); 4
          });
      })
  }

}
