import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BuildingData } from './building-data.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingmanagerDataService {

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/";


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(
    private httpClient: HttpClient,) {

  }

  public getRealestateList() {
    let url = this.REST_API_SERVER + "realestates/"
    return this.httpClient.get(url);
  }

  public getRealestateById(id: number): Observable<RealestateData> {
    return this.httpClient.get<RealestateData>(this.REST_API_SERVER + 'realestates/' + id + '/')
      .pipe(
        catchError(this.errorHandler)
      )
  }

  public create(data): Observable<RealestateData> {
    return this.httpClient.post<RealestateData>(this.REST_API_SERVER + 'realestates/', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  public update(id: number, realestate: RealestateData): Observable<RealestateData> {
    return this.httpClient.put<RealestateData>(this.REST_API_SERVER + 'realestates/' + id + '/', JSON.stringify(realestate), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  public getEmptyRealestateObject(): RealestateData {
    return {
      id: null,
      name: '',
      area_grounds: null,
      aquired_year: null,
      buildings: [],
      owner: -1,
      municipality: -1
    }
  }
}

export interface RealestateData {
  id: number;
  name: string;
  area_grounds: number;
  aquired_year: number;
  buildings: BuildingData[];
  owner: number;
  municipality: number;
}



