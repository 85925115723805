<h1 mat-dialog-title>Redigera Fastighet</h1>
<mat-dialog-content [formGroup]="form" fxLayout="column">
    
        <mat-form-field fxFill>
            <mat-label>Ägare</mat-label>
            <mat-select formControlName="owner">
                <mat-option *ngFor="let owner of ownerList" [value]="owner.id">
                    {{ owner.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>


    <div fxLayout="row" fxLayoutGap="1em">
        <mat-form-field>
            <mat-label>Fastighetsbeteckning</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Kommun</mat-label>
            <mat-select formControlName="municipality">
                <mat-option *ngFor="let municipality of municipalityList" [value]="municipality.id">
                  {{ municipality.name }}
                </mat-option>
              </mat-select>
        </mat-form-field>

        <button mat-button (click)="newMunicipalityDialog()">+</button>
    </div>
  
    <div fxLayout="row">
        <mat-form-field>
            <mat-label>Tomtarea</mat-label>
            <input matInput formControlName="area_grounds">
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-divider></mat-divider>
<div mat-dialog-actions>
  <button mat-button (click)="realestateDialogClose()">Avbryt</button>
  <button mat-button (click)="realestateDialogSave()">Spara</button>
</div>
