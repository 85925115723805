import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { InterestData, InterestDataService } from '../../services/interest-data.service';

@Component({
	selector: 'app-interestdetail',
	templateUrl: './interestdetail.component.html',
	styleUrls: ['./interestdetail.component.css']
})
export class InterestdetailComponent implements OnInit {

	interestId = -1;
	interestData;
	form: UntypedFormGroup;


	constructor(
		private route: ActivatedRoute,
		private interestDataService: InterestDataService,
	) { 
		this.interestData = interestDataService.getEmptyInterestObject();

		this.form = new UntypedFormGroup({
			name: new UntypedFormControl(this.interestData.name),
			ssn: new UntypedFormControl(this.interestData.ssn),
			email: new UntypedFormControl(this.interestData.email),
			phone: new UntypedFormControl(this.interestData.phone),
			currentLandlord: new UntypedFormControl(this.interestData.currentLandlord),
			currentStreet: new UntypedFormControl(this.interestData.currentStreet),
			currentAreacode: new UntypedFormControl(this.interestData.currentAreacode),
			currentCity: new UntypedFormControl(this.interestData.currentCity),
			employer: new UntypedFormControl(this.interestData.employer),
			employmentContact: new UntypedFormControl(this.interestData.employmentContact),
			employmentStartdate: new UntypedFormControl(this.interestData.employmentStartdate),
			employmentType: new UntypedFormControl(this.interestData.employmentType),
			employmentYearly: new UntypedFormControl(this.interestData.employmentYearly),
			comment: new UntypedFormControl(this.interestData.comment),
			apartementRok: new UntypedFormControl(this.interestData.apartementRok),
			apartementAdults: new UntypedFormControl(this.interestData.apartementAdults),
			apartementChildren: new UntypedFormControl(this.interestData.apartementChildren),
			marksCheckedStatus: new UntypedFormControl(this.interestData.marksCheckedStatus),
			marksLastUpdatedBy: new UntypedFormControl(this.interestData.marksLastUpdatedBy),
			id: new UntypedFormControl(this.interestData.id),
			
		});

		this.form.disable();
	}

	ngOnInit(): void {

		this.route.paramMap.subscribe(params => {
			this.interestId = Number(params.get('interestId'));
			console.log("url buildingId " + this.interestId);

			this.interestDataService.getById(this.interestId).subscribe((data: InterestData) => {
				console.log("realestateById = " + data);
				this.interestData = data;
				this.form.setValue(data);
			});
			
		});
	}

}
