import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MunicipalityData } from '../../services/municipality-data.service'

@Component({
  selector: 'app-editmunicipality-dialog',
  templateUrl: './editmunicipality-dialog.component.html',
  styleUrls: ['./editmunicipality-dialog.component.css']
})
export class EditmunicipalityDialogComponent implements OnInit {
  
  form: UntypedFormGroup;
  
  constructor(
    public dialogRef: MatDialogRef<EditmunicipalityDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: MunicipalityData
  ) { 
    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(),
   });
  }

  ngOnInit(): void {
  }

  onCancelClick(){
    console.log("Close and discard Realesteate dialog");
    this.dialogRef.close();
  }


  onSaveClick(){
    console.log("Save Realesteate dialog");
    this.dialogRef.close(this.form.value);
  }

}
