<mat-toolbar color="primary">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span>HKM Toolbox</span>
  <span fxFlex></span>
</mat-toolbar>
<mat-drawer-container class="sidenav-container" hasBackdrop="false" autosize>
  <mat-drawer #drawer class="sidenav, mat-elevation-z8" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="navService.navDrawerEnabled">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="size-64" inline="true">account_circle</mat-icon>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="mat-h3">
        {{currentUserData.username}}
      </div>
    </div>
    <mat-divider></mat-divider>
    <mat-nav-list>
      @if ( currentUserData.is_admin ){
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Fastigheter
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item routerLink="/buildingmanager">Fastigheter</a>
          <a mat-list-item routerLink="/buildingmanager/apartements">Lägenheter</a>
          <a mat-list-item routerLink="/buildingmanager">Maskiner</a>
        </mat-nav-list>
      </mat-expansion-panel>
      }

      @if ( currentUserData.role_administration ){
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Intresseanmälan
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item routerLink="/interestlist">Lista</a>
        </mat-nav-list>
      </mat-expansion-panel>
      }

      @if ( currentUserData.is_admin ){
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Energi
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item routerLink="/interestlist">Översikt</a>
          <a mat-list-item routerLink="/buildingmanager">Avläsning</a>
        </mat-nav-list>
      </mat-expansion-panel>
      }

      @if ( currentUserData.role_administration || currentUserData.role_maintenance){
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Uppdragslistor
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item routerLink="/tasklistwork">Arbetsflöde</a>
          <a mat-list-item routerLink="/tasklist">Mina ärenden</a>
          <a mat-list-item routerLink="/tasklistall">Alla ärenden</a>

          @if ( currentUserData.is_admin){
          <a mat-list-item routerLink="/tasklist/settings">Inställningar</a>
          }
        </mat-nav-list>
      </mat-expansion-panel>
      }

      @if (currentUserData.is_admin){
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Personal
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item routerLink="/personnelmanager">Personal</a>
          <a mat-list-item routerLink="/personnelmanager">Fordon</a>
        </mat-nav-list>
      </mat-expansion-panel>
      }

      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Konto
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item (click)="authService.logout()">Logga ut</a>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>

  <mat-drawer class="detailDrawer"  [class]="(isHandset$ | async) ? 'detailDrawerPhone' : 'detailDrawerDesktop'"  [opened]="navService.detailNavEnabled && authService.authenticated" mode="over" position="end" disableClose>
    <router-outlet name="detailOutlet"></router-outlet>
  </mat-drawer>
</mat-drawer-container>
