<h1 mat-dialog-title>Redigera Kommun</h1>
<div mat-dialog-content [formGroup]="form" fxLayout='column'>

    <div fxLayout="row">
        <mat-form-field>
            <mat-label>namn</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
    </div>
  
  <mat-divider></mat-divider>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Avbryt</button>
  <button mat-button (click)="onSaveClick()">Spara</button>
</div>