<h1 mat-dialog-title>Redigera Användare</h1>
<mat-dialog-content [formGroup]="form" fxFill fxLayout="column">
    <div fxLayout="row" fxFill>
        <mat-form-field fxFlex>
            <mat-label>Förnamn</mat-label>
            <input matInput formControlName="first_name">
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field fxFlex>
            <mat-label>Efternamn</mat-label>
            <input matInput formControlName="last_name">
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field fxFlex>
            <mat-label>Användarnamn</mat-label>
            <input matInput formControlName="username">
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field fxFlex>
            <mat-label>E-post</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field fxFlex>
            <mat-label>Telefon</mat-label>
            <input matInput formControlName="phone_number_1">
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFill></div>
    <div class=""></div>

</mat-dialog-content>
<div mat-dialog-actions fxLayout="column">
    <div fxFlayout="row" fxFill>
        <mat-divider fxFlex></mat-divider>
    </div>
    <div class="padding-16" fxFlayout="row" align="end" fxFill >
        <button mat-button (click)="cancelDialog()">Avbryt</button>
        <button mat-raised-button color="primary" (click)="saveDialog()">Spara</button>
    </div>
</div>