<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
  <mat-card-title>Uppdragslistor</mat-card-title>
  <mat-card-content fxLayout="column">
    <div fxLayout="row">
      <button mat-stroked-button (click)="editTasklistDialog(null)">Ny Uppdragslista</button>
    </div>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="card-padding-16">
  <mat-card-content fxLayout="column">
    <table mat-table [dataSource]="tasklistList" fxFlex>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Namn</th>
        <td mat-cell *matCellDef="let tasklist">{{tasklist.name}}</td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let tasklist"><button mat-icon-button [matMenuTriggerFor]="editMenu"
            [matMenuTriggerData]="{data: tasklist}">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>Ägare</th>
        <td mat-cell *matCellDef="let tasklist">{{getNameForTasklistOwner(tasklist)}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let tasklist; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>

<mat-menu matMenuContent #editMenu="matMenu">
  <ng-template matMenuContent let-data="data" align="end">
    <button mat-menu-item (click)="editTasklistDialog(data)">Redigera</button>
    <button mat-menu-item>Radera</button>
  </ng-template>
</mat-menu>
