<mat-card appearance="outlined" fxFill>
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxFill fxLayoutAlign="space-between">
        <h2>Ärende</h2>
        <div>&nbsp;</div><a [routerLink]="" (click)="closeDetailNav()"><mat-icon>close</mat-icon></a>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="16px" class="padding-below-16">
      <button mat-stroked-button (click)="editTaskDialog(task)"><mat-icon class="material-symbols-outlined-24"
          inline="true">edit_note</mat-icon>Redigera</button>
      <button mat-stroked-button (click)="markTaskAsDone(task)"><mat-icon class="material-symbols-outlined-24"
          inline="true">check</mat-icon>Kvittera</button>
      @if (closebuttonVisble) {
      <button mat-stroked-button (click)="markTaskAsCompleted(task)"><mat-icon class="material-symbols-outlined-24"
          inline="true">done_all</mat-icon>Avsluta</button>
      }
    </div>
    <mat-divider></mat-divider>
    <mat-tab-group animationDuration="200ms" fxFill>
      <mat-tab label="Beskrivning">
        <div fxLayout="row">

        </div>
        <div class="padding-1em">
          <div [formGroup]="form" fxLayoutGap="1em">
            <mat-form-field fxFill>
              <mat-label>Uppdragslista</mat-label>
              <mat-select formControlName="tasklist" required>
                <mat-option *ngFor="let tasklist of task_lists" [value]="tasklist.id">
                  {{tasklist.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFill>
              <mat-label>Rubrik</mat-label>
              <input matInput formControlName="title" required>
            </mat-form-field>

            <mat-form-field fxFill>
              <mat-label>Beskrivning</mat-label>
              <textarea matInput formControlName="description" md-maxlength="250" rows="2"></textarea>
            </mat-form-field>

            <mat-form-field fxFill>
              <mat-label>Address</mat-label>
              <textarea matInput formControlName="text_location" md-maxlength="150" rows="2"></textarea>
            </mat-form-field>

            <mat-form-field fxFill>
              <mat-label>Telefon</mat-label>
              <input matInput formControlName="contact_phone">
            </mat-form-field>

            <div fxFill fxLayoutGap="1em">
              <mat-label>Gå in med huvudnyckel</mat-label>
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button value="1">Ja</mat-radio-button>
                <mat-radio-button value="2">Nej</mat-radio-button>
              </mat-radio-group>
            </div>


            <div fxLayout="row" fxLayoutGap="1em">
              <mat-form-field>
                <mat-label>Tilldelad till</mat-label>
                <mat-select formControlName="assigned_to">
                  <mat-option *ngFor="let person of personnelList" [value]="person.id">
                    {{person.first_name}} {{person.last_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Prioritet</mat-label>
                <mat-select formControlName="priority">
                  <mat-option *ngFor="let priority of priority_list" [value]="priority.level">
                    {{priority.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxFill fxLayoutGap="1em">
              <mat-form-field>
                <mat-label>Externt id</mat-label>
                <input matInput formControlName="foreign_id">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Projektnummer (Byggsam)</mat-label>
                <input matInput formControlName="internal_id">
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Anteckningar">
        <app-progresslog [progresslogId]="task.progresslog"></app-progresslog>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
