import { EntranceData } from './../../../buildingmanager/services/entrance-data.service';
import { RealestateData } from 'src/app/buildingmanager/services/buildingmanager-data.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterestData } from '../../services/interest-data.service';


@Component({
  selector: 'app-editinterest-dialog',
  templateUrl: './editinterest-dialog.component.html',
  styleUrls: ['./editinterest-dialog.component.css']
})
export class EditinterestDialogComponent implements OnInit {


  form: UntypedFormGroup;
  interest: InterestData;

  constructor(
    public dialogRef: MatDialogRef<EditinterestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;

    console.log("data: " + JSON.stringify(data));

    this.interest = <InterestData>data;

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.interest.name),
      ssn: new UntypedFormControl(this.interest.ssn),
      email: new UntypedFormControl(this.interest.email),
      phone: new UntypedFormControl(this.interest.phone),
      currentLandlord: new UntypedFormControl(this.interest.currentLandlord),	  
      currentStreet: new UntypedFormControl(this.interest.currentStreet),
	  currentAreacode: new UntypedFormControl(this.interest.currentAreacode),
	  currentCity: new UntypedFormControl(this.interest.currentCity),
	  employer: new UntypedFormControl(this.interest.employer),
	  employmentContact: new UntypedFormControl(this.interest.employmentContact),
	  employmentStartdate: new UntypedFormControl(this.interest.employmentStartdate),	  
	  employmentType: new UntypedFormControl(this.interest.employmentType),
	  employmentYearly: new UntypedFormControl(this.interest.employmentYearly),
	  comment: new UntypedFormControl(this.interest.comment),
	  apartementRok: new UntypedFormControl(this.interest.apartementRok),
	  apartementAdults: new UntypedFormControl(this.interest.apartementAdults),
	  apartementChildren: new UntypedFormControl(this.interest.apartementChildren),
	  marksCheckedStatus: new UntypedFormControl(this.interest.marksCheckedStatus),
    });
  }

  ngOnInit(): void {
  }

  onCancelClick() {
    console.log("Close and discard Aparteent dialog");
    this.dialogRef.close();
  }


  onSaveClick() {
    console.log("Save apartement dialog");
    this.dialogRef.close(this.form.value);
  }
}
