<mat-card appearance="outlined">
	<mat-card-content>
		<div fxLayout="column" fxFlex>
			<mat-card appearance="outlined" class="mat-elevation-z0">
				<mat-card-content>
					<div class="mat-headline-6"> {{realestate.name}}</div>
					<div fxLayout="row">
						<div fxLayout="column" fxFlex>
							<div>
								<div class="mat-caption">Fastighetsbeteckning:</div>
								<div class="mat-body-strong">{{ realestate.name}}, {{
									municipalityDataService.getMunicipalityName(realestate.municipality) }}</div>
							</div>
							<div>
								<div class="mat-caption">Tomtarea:</div>
								<div class="mat-body-strong">{{ realestate.area_grounds}} kvm</div>
							</div>
							<div>
								<div class="mat-caption">Inköpsår:</div>
								<div class="mat-body-strong">{{ realestate.aquired_year}}</div>
							</div>
						</div>

						<app-map-component [realestate]=realestate></app-map-component>
					</div>
				</mat-card-content>
			</mat-card>

			<mat-card appearance="outlined" fxFlex class="mat-elevation-z0">
				<mat-card-content>
					<mat-tab-group dynamicHeight animationDuration="0ms">
						<mat-tab label="Byggnader">
							<mat-card appearance="outlined" fxFlex class="mat-elevation-z2">
								<mat-card-content>
									<mat-accordion>
										<mat-expansion-panel *ngFor="let building of realestate.buildings">
											<mat-expansion-panel-header>
												<mat-panel-title>
													<div>{{ building.name }}</div>
												</mat-panel-title>
											</mat-expansion-panel-header>
											<div fxLayout="column">
												<div>
													<div>Byggnadsarea: {{ building.area_foundation }}</div>
													<div>Bruttoarea: {{ building.area_building }}</div>
													<div>Byggnadsår: {{ building.building_year }}</div>
												</div>
												<mat-divider></mat-divider>
												<table mat-table [dataSource]="building.street_entrances" fxFlex>
													<ng-container matColumnDef="streetname">
														<th mat-header-cell *matHeaderCellDef>Gatunamn</th>
														<td mat-cell *matCellDef="let entrance">{{entrance.streetname}}
														</td>
													</ng-container>
													<ng-container matColumnDef="edit">
														<th mat-header-cell *matHeaderCellDef></th>
														<td mat-cell *matCellDef="let entrance"><button mat-icon-button
																[matMenuTriggerFor]="editMenu"
																[matMenuTriggerData]="{data: {entrance: entrance, building: building}}">
																<mat-icon>more_vert</mat-icon>
															</button></td>
													</ng-container>
													<ng-container matColumnDef="streetnumber">
														<th mat-header-cell *matHeaderCellDef>Gatunummer</th>
														<td mat-cell *matCellDef="let entrance">{{ entrance.number }}
														</td>
													</ng-container>
													<ng-container matColumnDef="zipcode">
														<th mat-header-cell *matHeaderCellDef>Postnummer</th>
														<td mat-cell *matCellDef="let entrance">{{entrance.zipcode}}
														</td>
													</ng-container>
													<ng-container matColumnDef="city">
														<th mat-header-cell *matHeaderCellDef>Ort</th>
														<td mat-cell *matCellDef="let entrance">{{entrance.city}}</td>
													</ng-container>

													<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
													<tr mat-row *matRowDef="let realestate; columns: displayedColumns">
													</tr>

													<div *ngIf="building.street_entrances.length === 0">
														Ingen postadress
													</div>
												</table>
												<mat-action-row>
													<button mat-button color="primary"
														(click)="editEntranceDialog(realestate, building, null)">Ny
														Entré</button>
												</mat-action-row>
											</div>

										</mat-expansion-panel>
									</mat-accordion>
								</mat-card-content>
								<mat-card-actions>
									<button mat-button color="primary" (click)="newBuildingDialog(realestate)">Ny
										Byggnad</button>
								</mat-card-actions>
							</mat-card>
						</mat-tab>
						<mat-tab label="Lägenheter">
							<app-apartementlist [realestate]="realestate"></app-apartementlist>
						</mat-tab>
						<mat-tab label="Lokaler">

						</mat-tab>
						<mat-tab label="Maskiner">

						</mat-tab>
						<mat-tab label="Underhåll">

						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</mat-card-content>
</mat-card>

<mat-menu matMenuContent #editMenu="matMenu">
	<ng-template matMenuContent let-data="data" align="end">
		<button mat-menu-item (click)="editEntranceFromMenu(realestate, data)">Redigera</button>
		<button mat-menu-item (click)="deleteEntranceFromMenu(realestate, data)">Radera</button>
	</ng-template>
</mat-menu>
