import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ProgresslogEntryData, ProgresslogDataService, ProgresslogData } from './services/progresslog-data.service';
import { MatDialog } from '@angular/material/dialog';
import { EditProgresslogDialogComponent } from './dialogs/edit-progresslog-dialog/edit-progresslog-dialog.component';

@Component({
  selector: 'app-progresslog',
  templateUrl: './progresslog.component.html',
  styleUrls: ['./progresslog.component.css']
})
export class ProgresslogComponent implements OnInit {
  @Input() progresslogId: string;

  progresslog: ProgresslogData = this.progresslogDataService.getEmptyProgresslogDataObject();

  constructor(
    private progresslogDataService: ProgresslogDataService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    console.log("progresslogId: " + this.progresslogId);
    this.refreshProgresslog();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("progresslogId: " + this.progresslogId);
    this.progresslogId = changes.progresslogId.currentValue;
    this.refreshProgresslog();

    //    this.doSomething(changes.categoryId.currentValue);
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }


  refreshProgresslog(): void {
    if (this.progresslogId == null) {
      return;
    }

    this.progresslogDataService.getProgresslog(this.progresslogId).subscribe((data: any) => {
      data.entries.sort((a, b) => (a.created_date < b.created_date) ? 1 : -1);

      this.progresslog = data;
    });
  }


  addEntryDialog(logEntry: ProgresslogEntryData): void {
    let createNewEntry = false;

    if (logEntry == null) {
      createNewEntry = true;
      logEntry = this.progresslogDataService.getEmptyProgresslogEntryObject();
    }

    this.dialog.open(EditProgresslogDialogComponent, { data: logEntry, width: '500px' }).afterClosed().subscribe(
      data => {
        if (data == undefined) {
          return;
        }

        if (createNewEntry) {
          this.progresslogDataService.createProgresslogEntry(this.progresslog, data).subscribe(res => {
            console.log("create ok");
            this.refreshProgresslog();
          },
            err => {
              console.log("create fail " + err);
            });
        }
        else {
        }
      });
  }
}
