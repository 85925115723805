<div fxLayout="column" fxFlexFill>
  <h1 mat-dialog-title>Redigera Lägenhet</h1>
  <div mat-dialog-content [formGroup]="form" fxFlexFill>
    <div fxLayout="column">
      <div fxLayout="row" fxFlexFill>
        <mat-form-field>
          <mat-label>Byggnad</mat-label>
          <mat-select formControlName="building" (selectionChange)="updateEntranceSelect($event)">
            <mat-option *ngFor="let building of realestate.buildings" [value]="building.id">
              {{ building.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field>
          <mat-label>Gatuadress</mat-label>
          <mat-select formControlName="entrance">
            <mat-option *ngFor="let entrance of entranceList" [value]="entrance.id">
              {{ entrance.streetname }} {{ entrance.number }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <div fxLayout="row">
          <mat-form-field>
            <mat-label>Lägenhets id</mat-label>
            <input matInput formControlName="identifier">
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field>
            <mat-label>SKV id</mat-label>
            <input matInput formControlName="skvIdentifier">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <mat-form-field>
          <mat-label>Våning</mat-label>
          <input matInput formControlName="level">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field>
          <mat-label>Area</mat-label>
          <input matInput formControlName="area">
        </mat-form-field>
        <mat-form-field>
          <mat-label>RoK</mat-label>
          <input matInput formControlName="rok">
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">Avbryt</button>
    <button mat-button (click)="onSaveClick()">Spara</button>
  </div>
</div>
