import { Personnel, PersonnelDataService } from './../../../personnellist/personnel-data.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tasklist, TasklistDataService } from '../../services/tasklist-data.service';

@Component({
  selector: 'app-edittasklist-dialog',
  templateUrl: './edittasklist-dialog.component.html',
  styleUrls: ['./edittasklist-dialog.component.css']
})
export class EditTasklistDialogComponent implements OnInit {

  form: UntypedFormGroup;
  personnelList = [];

  constructor(
    private dialogRef: MatDialogRef<EditTasklistDialogComponent>,
    private dialog: MatDialog,
    private tasklistService: TasklistDataService,
    private personnelService: PersonnelDataService,
    @Inject(MAT_DIALOG_DATA) public data: Tasklist
  ) {

    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name),
      owner: new UntypedFormControl(data.owner),
      members: new UntypedFormControl(data.members),
      send_notification: new UntypedFormControl(data.send_notification),
    });
  }

  ngOnInit(): void {
    this.personnelService.getAll().subscribe((data: Personnel[]) => { this.personnelList = data });
  }

  cancelDialog(){
    this.dialogRef.close();
  }

  saveDialog(){
    this.dialogRef.close(this.form.value);
  }
}
