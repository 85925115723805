import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntranceData } from 'src/app/buildingmanager/services/entrance-data.service';

@Component({
  selector: 'app-editentrance-dialog',
  templateUrl: './editentrance-dialog.component.html',
  styleUrls: ['./editentrance-dialog.component.css']
})
export class EditentranceDialogComponent implements OnInit {
 
  form: UntypedFormGroup;
  
  constructor(
    public dialogRef: MatDialogRef<EditentranceDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: EntranceData
  ) { 
    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      streetname: new UntypedFormControl(data.streetname),
      number: new UntypedFormControl(data.number),
      zipcode: new UntypedFormControl(data.zipcode),
      city: new UntypedFormControl(data.city),
   });
  }

  ngOnInit(): void {
  }

  onCancelClick(){
    this.dialogRef.close();
  }


  onSaveClick(){
    this.dialogRef.close(this.form.value);
  }
}
