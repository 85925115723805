<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
    <mat-card-header>
        <mat-card-title>Personal</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row">
            <button mat-button (click)="editPersonnelDialog(null)"><mat-icon class="size-64" inline="true">person_add</mat-icon> Ny Användare</button>
        </div>

    </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
    <mat-card-content>
        <div fxLayout="column">
            <table mat-table [dataSource]="personnelList">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Namn</th>
                    <td mat-cell *matCellDef="let personnel"><a class="navlink" [routerLink]="" (click)="showPersonDetail(personnel)">{{personnel.first_name}} {{personnel.last_name}}</a></td>
                </ng-container>
                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef>Användare</th>
                    <td mat-cell *matCellDef="let personnel"> {{personnel.username}}</td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Telefon</th>
                    <td mat-cell *matCellDef="let personnel"> {{personnel.phone_number_1}} /
                        {{personnel.phone_number_2}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>e-post</th>
                    <td mat-cell *matCellDef="let personnel"> {{personnel.email}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>

<mat-menu matMenuContent #editMenu="matMenu">
    <ng-template matMenuContent let-data="data" align="end">
        <button mat-menu-item (click)="editPersonnelDialog(data)">Redigera</button>
        <button mat-menu-item>Radera</button>
    </ng-template>
</mat-menu>
