import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BuildingData } from 'src/app/buildingmanager/services/building-data.service';
import { RealestateData } from 'src/app/buildingmanager/services/buildingmanager-data.service';
import { EntranceData } from 'src/app/buildingmanager/services/entrance-data.service';


@Injectable({
	providedIn: 'root'
})
export class ApartementDataService {
	private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/realestates/";

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
	) {

	}

	getApartementsForRealestate(realestate: RealestateData): Observable<ApartementData[]> {
		return this.httpClient.get<ApartementData[]>(this.REST_API_SERVER + realestate.id +
			'/apartements/')
			.pipe(
				catchError(this.errorHandler)
			)
	}

  getApartementsListGroupedByRealestate(): Observable<Object>{
    return this.httpClient.get("http://localhost:8000/api/v2/apartementsByRealestate/")
			.pipe(
				catchError(this.errorHandler)
			)
  }

	create(realestate: RealestateData, apartement: ApartementData): Observable<ApartementData> {
		return this.httpClient.post<ApartementData>(this.REST_API_SERVER + realestate.id +
			'/apartements/', JSON.stringify(apartement), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	getById(id: number): Observable<ApartementData> {
		return this.httpClient.get<ApartementData>(this.REST_API_SERVER + id)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	getAll(): Observable<ApartementData[]> {
		return this.httpClient.get<ApartementData[]>(this.REST_API_SERVER)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	update(realestateId: number, apartementId: number, building: BuildingData): Observable<BuildingData> {
		return this.httpClient.put<BuildingData>(this.REST_API_SERVER + realestateId +'/apartements/' + apartementId + '/', JSON.stringify(building), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	delete(id: number) {
		return this.httpClient.delete<ApartementData>(this.REST_API_SERVER + id, this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	errorHandler(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}

	public getEmptyApartementObject(): ApartementData {
		return {
			id: null,
			identifier: '',
			skvIdentifier: '',
			area: '',
			entranceStreetAddress: null,
			building: null,
      rok: 0,
      level: 0,
		}
	}
}


export interface ApartementData {
	id: number;
	identifier: string;
	skvIdentifier: string;
	area: string;
	entranceStreetAddress: number;
	building: number;
  level: number;
  rok: number;

}
