import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { BuildingmanagerDataService } from '../../services/buildingmanager-data.service';

import { RealestateData } from '../../services/buildingmanager-data.service';
import { MunicipalityDataService } from '../../services/municipality-data.service';

import { EditbuildingDialogComponent } from '../../dialogs/editbuilding-dialog/editbuilding-dialog.component';
import { EditentranceDialogComponent } from '../../dialogs/editentrance-dialog/editentrance-dialog.component';

import { BuildingDataService, BuildingData } from '../../services/building-data.service';
import { EntranceDataService, EntranceData } from '../../services/entrance-data.service';

import { ApartementlistComponent } from '../../../apartementmanager/components/apartementlist/apartementlist.component';

@Component({
	selector: 'app-buildingdetail',
	templateUrl: './buildingdetail.component.html',
	styleUrls: ['./buildingdetail.component.css']
})
export class BuildingdetailComponent implements OnInit {

	buildingId = -1;
	realestate;
	building;

	displayedColumns = ['streetname', 'edit', 'streetnumber', 'zipcode', 'city'];

	constructor(
		private route: ActivatedRoute,
		private buildingmanagerDataService: BuildingmanagerDataService,
		public municipalityDataService: MunicipalityDataService,
		public dialog: MatDialog,
		public buildingDataService: BuildingDataService,
		public entranceDataService: EntranceDataService,
	) { }

	ngOnInit(): void {
		console.log("buildingDetailComponent onInit()");

		this.route.paramMap.subscribe(params => {
			this.buildingId = Number(params.get('buildingId'));
			console.log("url buildingId " + this.buildingId);

			this.buildingmanagerDataService.getRealestateById(this.buildingId).subscribe((data: RealestateData) => {
				console.log("realestateById = " + data);
				this.realestate = data;
			});

		});

	}

	newBuildingDialog(realestate: RealestateData) {
		this.dialog.open(EditbuildingDialogComponent).afterClosed().subscribe(
			data => {
				console.log("Dialog output:", data);
				this.buildingDataService.create(realestate, data).subscribe(res => {
					console.log("create ok");

					this.buildingmanagerDataService.getRealestateById(this.buildingId).subscribe((data: RealestateData) => {
						console.log("realestateById = " + data);
						this.realestate = data;
					});
				},
					err => {
						console.log("create fail " + err);
					});
			});
	}


	realestateDialogSave() {
	}

	realestateDialogClose() {
	}

	deleteEntranceFromMenu(realestate, data) {
		let building = data.building
		let entrance = data.entrance;
		console.log(JSON.stringify(entrance));

		this.entranceDataService.delete(realestate, building, entrance).subscribe(res => {
			console.log("delete ok");

			this.buildingmanagerDataService.getRealestateById(this.buildingId).subscribe((data: RealestateData) => {
				this.realestate = data;
			});
		},
			err => {
				console.log("update fail " + err); 4
			});


	}

	// Called from menu with parameters in dictionary
	editEntranceFromMenu(realestate: RealestateData, data) {
		this.editEntranceDialog(realestate, data.building, data.entrance);
	}

	// Called from button in list with explicit parameters, or indirectly from menu function
	editEntranceDialog(realestate: RealestateData, building: BuildingData, entrance: EntranceData) {
		let createNewEntrance = false;

		if (entrance == null) {
			console.log("CREATING NEW");
			createNewEntrance = true;
			entrance = this.entranceDataService.getEmptyEntranceObject();
		}

		this.dialog.open(EditentranceDialogComponent, { data: entrance }).afterClosed().subscribe(
			tData => {
				console.log("Dialog output:", tData);
				if (createNewEntrance) {
					this.entranceDataService.create(realestate, building, tData).subscribe(res => {
						console.log("create ok");

						this.buildingmanagerDataService.getRealestateById(this.buildingId).subscribe((data: RealestateData) => {
							this.realestate = data;
						});
					},
						err => {
							console.log("create fail " + err); 4
						});
				} else {
					this.entranceDataService.update(realestate, building, entrance.id, tData).subscribe(res => {
						console.log("update ok");

						this.buildingmanagerDataService.getRealestateById(this.buildingId).subscribe((data: RealestateData) => {
							this.realestate = data;
						});
					},
						err => {
							console.log("update fail " + err); 4
						});
				}

			});
	}
}

