import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NavService } from './services/nav.service';
import { AuthenticationService } from '../authentication/services/authentication.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit{
  detailDrawerOpened: Boolean = false;

  currentUserData = null;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
    public navService: NavService,
    public authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.authService.getChangeEmitter().subscribe(data => {
        this.currentUserData = this.authService.getCurrentUserDataValue();
      }
    );

    this.currentUserData = this.authService.getCurrentUserDataValue();
  }

}
