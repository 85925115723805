import { TasklistDataService } from './services/tasklist-data.service';
import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EdittaskDialogComponent } from './dialogs/edittask-dialog/edittask-dialog.component';
import { Task } from './services/tasklist-data.service';
import { Tasklist } from './services/tasklist-data.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { NavService } from '../nav/services/nav.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-tasklist',
  templateUrl: './tasklist.component.html',
  styleUrls: ['./tasklist.component.css']
})
export class TasklistComponent implements OnInit {
  tasklistList = Array<Tasklist>();
  taskList = Array<Task>();

  myTasksDataSource = new MatTableDataSource<Task>();


  constructor(
    private dialog: MatDialog,
    private tasklistDataService: TasklistDataService) {
  }

  ngOnInit(): void {
    this.refreshTasklist();
  }


  refreshTasklist(): void {
    this.tasklistDataService.getAll().subscribe((data: any[]) => {
      this.tasklistList = data;
    });

    this.tasklistDataService.getAllTasksForCurrentUser().subscribe((data: any[]) => {
      this.taskList = data;
      this.myTasksDataSource.data = this.taskList;
    });
  }



  editTaskDialog(task: Task): void {
    let createNewTask = false;

    if (task == null) {
      createNewTask = true;
      task = this.tasklistDataService.getEmptyTaskObject();
    }

    console.log("task: '" + task + "' createNewTask: '" + createNewTask + "'");
    console.log(JSON.stringify(task));

    this.dialog.open(EdittaskDialogComponent, { data: task, width: '500px' }).afterClosed().subscribe(
      data => {
        if (createNewTask) {
          this.tasklistDataService.createNewTask(data).subscribe(res => {
            console.log("create ok");
            this.refreshTasklist();
          },
            err => {
              console.log("create fail " + err);
            });
        }
        else {
          this.tasklistDataService.updateTask(task.id, data).subscribe(res => {
            console.log("update ok");
            this.refreshTasklist();
          },
            err => {
              console.log("update fail " + err);
            });
        }
      });
  }
}
