<table mat-table [dataSource]="tasklistDataSource" matSort (matSortChange)="sortData($event)" matSortActive="created" matSortDirection="desc" fxFlex>
  <ng-container matColumnDef="checked">
    <th mat-header-cell *matHeaderCellDef class="w-1em">
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let task">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? toggleSelect(task) : null"
                    [checked]="selection.isSelected(task)">
      </mat-checkbox>
  </td>
  </ng-container>
  <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Rubrik</th>
      <td mat-cell *matCellDef="let task"><a class="navlink" [routerLink]="" (click)="showTaskDetail(task)">{{task.title}}</a></td>
  </ng-container>
  <ng-container matColumnDef="assigned">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="assigned">Tilldelad</th>
    <td mat-cell *matCellDef="let task">{{getNameFromId(task.assigned_to)}}</td>
  </ng-container>
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="created">Skapad</th>
    <td mat-cell *matCellDef="let task">{{task.created_date | date: 'yyyy-MM-dd HH:mm'}}</td>
  </ng-container>
  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Skapad Av</th>
    <td mat-cell *matCellDef="let task">{{getNameFromId(task.created_by)}}</td>
  </ng-container>
  <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let task"><mat-icon>more_vert</mat-icon></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let task; columns: displayedColumns"></tr>
</table>
