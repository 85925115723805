import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TasklistComponent } from './tasklist/tasklist.component';
import { BuildingmanagerComponent } from './buildingmanager/buildingmanager.component';
import { ApartementmanagerComponent } from './apartementmanager/apartementmanager.component';
import { PersonnellistComponent } from './personnellist/personnellist.component';
import { BuildingdetailComponent } from './buildingmanager/components/buildingdetail/buildingdetail.component';
import { TasklistSettingsComponent } from './tasklist/components/tasklist-settings/tasklist-settings.component';
import { AuthGuard } from './authentication/helpers/auth-guard';
import { LoginComponent } from './authentication/components/login/login.component';
import { InterestlistComponent } from './interestlist/interestlist.component';
import { InterestdetailComponent } from './interestlist/components/interestdetail/interestdetail.component';
import { TaskdetailComponent } from './tasklist/components/taskdetail/taskdetail.component';
import { TasklistallComponent } from './tasklist/components/tasklistall/tasklistall.component';
import { TasklistworkComponent } from './tasklist/components/tasklistwork/tasklistwork.component';
import { PersonnelDetailComponent } from './personnellist/components/personnel-detail/personnel-detail.component';


const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: 'tasklist', component: TasklistComponent, canActivate: [AuthGuard] },
	{ path: 'tasklist/settings', component: TasklistSettingsComponent, canActivate: [AuthGuard] },
	{ path: 'tasklistall', component: TasklistallComponent, canActivate: [AuthGuard] },
	{ path: 'tasklistwork', component: TasklistworkComponent, canActivate: [AuthGuard] },
	{ path: 'interestlist', component: InterestlistComponent, canActivate: [AuthGuard] },
	{ path: 'buildingmanager', component: BuildingmanagerComponent, canActivate: [AuthGuard] },
	{ path: 'buildingmanager/apartements', component: ApartementmanagerComponent, canActivate: [AuthGuard] },
	{ path: 'personnelmanager', component: PersonnellistComponent, canActivate: [AuthGuard] },
	{ path: 'buildingdetail/:buildingId', component: BuildingdetailComponent, outlet: 'detailOutlet', canActivate: [AuthGuard] },
	{ path: 'interestdetail/:interestId', component: InterestdetailComponent, outlet: 'detailOutlet', canActivate: [AuthGuard] },
	{ path: 'taskdetail/:taskId', component: TaskdetailComponent, outlet: 'detailOutlet', canActivate: [AuthGuard] },
  { path: 'personneldetail/:personId', component: PersonnelDetailComponent, outlet: 'detailOutlet', canActivate: [AuthGuard] },

];

@NgModule({
	imports: [RouterModule.forRoot(routes, { enableTracing: false })],
	exports: [RouterModule]
})
export class AppRoutingModule { }

