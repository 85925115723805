import { NavService } from './../nav/services/nav.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EditinterestDialogComponent } from './dialogs/editinterest-dialog/editinterest-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';


import { InterestData, InterestDataService } from './services/interest-data.service';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
	selector: 'app-interestlist',
	templateUrl: './interestlist.component.html',
	styleUrls: ['./interestlist.component.css']
})
export class InterestlistComponent implements OnInit, OnDestroy {

	interestList = [];
	activeInterest = null;

	selection: SelectionModel<InterestData>;
	initialSelection = [];
	allowMultiSelect = false;

	displayedColumns: string[] = ['checked', 'name', 'phone', 'ssn', 'checkedState'];


	constructor(private navService: NavService,
		private interestService: InterestDataService,
		private dialog: MatDialog,
		private route: Router,) { }

	ngOnInit(): void {
		this.navService.hideDetailNav();
		this.selection = new SelectionModel<InterestData>(this.allowMultiSelect, this.initialSelection);

		this.interestService.getAll().subscribe((data: any[]) => { this.interestList = data; });

	}


	ngOnDestroy(): void {
		this.navService.hideDetailNav();
	}

	showInterestDetail(interest: InterestData): void {
		this.activeInterest = interest;
		this.route.navigate(['', { outlets: { detailOutlet: ['interestdetail', interest.id] } }]);
	  }

	toggleSelect(interest: InterestData) {
		this.selection.toggle(interest);
	
		console.log("toggleSelect()" + this.selection.selected.length);
	
		if (this.selection.selected.length == 1) {
		  this.navService.showDetailNav();
		  this.showInterestDetail(this.selection.selected[0]);
		}
		else {
		  this.navService.hideDetailNav();
		}
	  }

	newInterestDialog(interestData: InterestData): void {
		let createNewInterest = false;

		if (interestData == null) {
			createNewInterest = true
			interestData = this.interestService.getEmptyInterestObject();
		}

		this.dialog.open(EditinterestDialogComponent, {data: interestData}).afterClosed().subscribe(
			data => {
				console.log("Dialog output:", data);
				this.interestService.create(data).subscribe(res => {
					console.log("create ok");
					this.interestService.getAll().subscribe((data: any[]) => { this.interestList = data; });
				},
					err => {
						console.log("create fail " + err);
					});
			});
	}
}
