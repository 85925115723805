import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule} from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialogContent } from '@angular/material/dialog';

import { AuthenticationService } from './../../../authentication/services/authentication.service';
import { SnackbarService } from './../../../util/services/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Personnel, PersonnelDataService } from '../../personnel-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ProgresslogDataService } from 'src/app/progresslog/services/progresslog-data.service';
import { NavService } from 'src/app/nav/services/nav.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChangePasswordDialogComponent } from '../../dialogs/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-personnel-detail',
  templateUrl: './personnel-detail.component.html',
  styleUrls: ['./personnel-detail.component.css']
})
export class PersonnelDetailComponent implements OnInit {

  personnel: Personnel;
  personId = -1;

  constructor(
    private route: ActivatedRoute,
    private personnelDS: PersonnelDataService,
    private dialog: MatDialog,
    private progressLogDS: ProgresslogDataService,
    private AuthenticationService: AuthenticationService,
    private navService: NavService,
    private snackbarService: SnackbarService) {

    this.personnel = personnelDS.getEmptyPersonnelObject();

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.personId = Number(params['personId']);

      this.personnelDS.getById(this.personId).subscribe((data: Personnel) => {
        this.personnel = data;
        this.snackbarService.message("loaded ok")
      });
    });
  }


  saveRoles(): void {
    this.personnelDS.update(this.personId, this.personnel).subscribe((data: Personnel) => {
      this.snackbarService.message("saved ok")
      this.AuthenticationService.getCurrentUserData();
    });
  }


  setNewPassword(): void {
    this.dialog.open(ChangePasswordDialogComponent, { data: this.personnel, width: '400px' }).afterClosed().subscribe(
      data => {
        console.log("change password: " + JSON.stringify(data));
        console.log("change personnel: " + JSON.stringify(this.personnel));

        this.personnelDS.changePassword(this.personnel.id, data).subscribe(res => {
          this.snackbarService.message("password updated ok");
        },
          err => {
            console.log("update fail " + err);
            this.snackbarService.message("Password update failed");
          });
      });
  }

  closeDetailNav(): void {
    this.navService.hideDetailNav();
  }
}
