<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
    <mat-card-content fxLayout="column">
        <mat-card-title>Fastigheter</mat-card-title>
        <div fxLayout="row">
            <button mat-button (click)="editRealestateDialog(null)">Ny Fastighet</button>
            <button mat-button (click)="newOwnerDialog()">Ny Fastighetsägare</button>
            <span fxFlex></span>
            <button mat-button (click)="editRealestateDialog(null)">Radera Fastighet</button>
        </div>

        <table mat-table [dataSource]="realestatesList" fxFlex>

            <ng-container matColumnDef="checked">
              <th mat-header-cell *matHeaderCellDef class="w-3em">
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let realestate">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? toggleSelect(realestate) : null"
                              [checked]="selection.isSelected(realestate)">
                </mat-checkbox>
            </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Namn</th>
                <td mat-cell *matCellDef="let realestate">{{realestate.name}}</td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let realestate"><button  mat-icon-button [matMenuTriggerFor]="editMenu" [matMenuTriggerData]="{data: realestate}"><mat-icon>more_vert</mat-icon></button></td>
            </ng-container>
            <ng-container matColumnDef="municipality">
                <th mat-header-cell *matHeaderCellDef>Kommun</th>
                <td mat-cell *matCellDef="let realestate">{{getMunicipalityName(realestate.municipality)}}</td>
            </ng-container>
            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef>Ägare</th>
                <td mat-cell *matCellDef="let realestate">{{getOwnerName(realestate.owner)}}</td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let realestate; columns: displayedColumns"></tr>
        </table>
    </mat-card-content>
</mat-card>

<mat-menu matMenuContent #editMenu="matMenu">
  <ng-template matMenuContent let-data="data" align="end">
    <button mat-menu-item (click)="editRealestateDialog(data)">Redigera</button>
    <button mat-menu-item>Radera</button>
  </ng-template>
</mat-menu>

<!---
 (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(realestate) : null"
                                    [checked]="selection.isSelected(realestate)">


                                                    <mat-header-cell *matHeaderCellDef>Check</mat-header-cell>
                <mat-cell *matCellDef="let realestate">
                  <mat-checkbox [checked]="true">ASD</mat-checkbox>
                </mat-cell>
-->
