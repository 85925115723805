<mat-card appearance="outlined" class="mat-mdc-elevation-specific mat-elevation-z1 card-padding-16">
  <mat-card-title>Ärenden i flöde</mat-card-title>
  <mat-card-content fxLayout="column">
    <div fxLayout="row" fxLayoutGap="1em">
      <button mat-stroked-button color="primary" (click)="editTaskDialog(null)" class="app-buttonsize-150"><mat-icon
          class="size-64" inline="true">add_task</mat-icon> Ny Uppgift</button>
      <span fxFlex></span>
      <form fxLayoutGap="1em">
        <mat-form-field fxFlex="400px">
          <mat-label>Urval</mat-label>
          <mat-select placeholder="Välj ärendelista"
            (selectionChange)="onTaskListChange($event)">
            <mat-option *ngFor="let tasklist of filterTasklist" [value]="tasklist">
              {{tasklist.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-mdc-elevation-specific mat-elevation-z0 card-padding-16">
  <mat-card-title>Ärenden hos mig</mat-card-title>
  <mat-card-content fxLayout="column">
    <app-tasklisttable [tasklistDataSource]=myAssignedTasksDataSource></app-tasklisttable>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-mdc-elevation-specific mat-elevation-z0 card-padding-16">
  <mat-card-title>Mina ärenden hos andra</mat-card-title>
  <mat-card-content fxLayout="column">
    <app-tasklisttable [tasklistDataSource]=myTasksAssignedToOthersDataSource></app-tasklisttable>

  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-mdc-elevation-specific mat-elevation-z0 card-padding-16">
  <mat-card-title>Avslutade ärenden</mat-card-title>
  <mat-card-content fxLayout="column">
    <app-tasklisttable [tasklistDataSource]=myClosedTasksDataSource></app-tasklisttable>

  </mat-card-content>
</mat-card>
