import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication/services/authentication.service';
import { User } from './authentication/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'HKM Tools 3';

  user: User;

  constructor(public authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    console.log("AppComponent.ngOnInit() called");
    this.authenticationService.currentUser.subscribe(x => this.user = x);

  }

  logout() {
    this.authenticationService.logout();
  }

}
