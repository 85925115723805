import { ProgresslogData, ProgresslogEntryData } from './../../services/progresslog-data.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-progresslog-dialog',
  templateUrl: './edit-progresslog-dialog.component.html',
  styleUrls: ['./edit-progresslog-dialog.component.css']
})
export class EditProgresslogDialogComponent implements OnInit{
  form: UntypedFormGroup;


  constructor(
    private dialogRef: MatDialogRef<EditProgresslogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProgresslogEntryData
  ){
    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(data.id),
      entry: new UntypedFormControl(data.entry, [Validators.required, Validators.minLength(1)]),
      created_date: new UntypedFormControl(data.created_date),
      created_by: new UntypedFormControl(data.created_by),
    });
  }

  ngOnInit(): void {

  }

  cancelDialog(){
    this.dialogRef.close();
  }

  saveDialog(){
    this.dialogRef.close(this.form.value);
  }
}
