import { AuthenticationService } from './../../../authentication/services/authentication.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Task, Tasklist, TasklistDataService } from '../../services/tasklist-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { EdittaskDialogComponent } from '../../dialogs/edittask-dialog/edittask-dialog.component';
import { MatSelect } from '@angular/material/select';


@Component({
  selector: 'app-tasklistwork',
  templateUrl: './tasklistwork.component.html',
  styleUrls: ['./tasklistwork.component.css']
})
export class TasklistworkComponent implements OnInit {

  tasklistList = Array<Tasklist>();
  filterTasklist = Array<Tasklist>();
  selectedFilterListId = -1;

  rawTasklist = Array<Task>();
  myTasks = Array<Task>();
  myAssignedTasks = Array<Task>();
  myTasksAssignedToOthers = Array<Task>();
  myClosedTasks = Array<Task>();

  currentUser = null;

  myAssignedTasksDataSource = new MatTableDataSource<Task>();
  myTasksAssignedToOthersDataSource = new MatTableDataSource<Task>();
  myClosedTasksDataSource = new MatTableDataSource<Task>();

  constructor(private tasklistDataService: TasklistDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.refreshTasklist()

    this.tasklistDataService.getChangeEmitter().subscribe(() => {
      console.log("tasklistwork.component.ts getChangeEmitter() received change event");
      this.refreshTasklist();
    });

    this.currentUser = this.authenticationService.getCurrentUserDataValue();
  }

  sortTasks(): void {
    console.log("--> sortTasks() sorting");


    this.myTasks.length = 0;

    for (let task of this.rawTasklist) {
      if (this.selectedFilterListId == -1 ||  (task.tasklist as any) == this.selectedFilterListId) {
        this.myTasks.push(task);
      }
    }

    console.log("myTasks: " + JSON.stringify(this.myTasks));

    this.myTasksAssignedToOthers.length = 0;
    this.myAssignedTasks.length = 0;
    this.myClosedTasks.length = 0;

    for (let task of this.myTasks) {
      if (task.completed){
        this.myClosedTasks.push(task);
      } else if ((task.created_by == this.currentUser.id) && (task.assigned_to != this.currentUser.id)) {
        this.myTasksAssignedToOthers.push(task);
      } else if (this.currentUser.id == task.assigned_to) {
        this.myAssignedTasks.push(task);
      } else {
        console.log("DID NOT MATCH : sortTasks() task: " + JSON.stringify(task));
      }
    }

    this.myAssignedTasksDataSource.data = this.myAssignedTasks;
    this.myTasksAssignedToOthersDataSource.data = this.myTasksAssignedToOthers;
    this.myClosedTasksDataSource.data = this.myClosedTasks;

//    console.log("myTasksAssignedToOthers: " + JSON.stringify(this.myTasksAssignedToOthers));
//    console.log("myAssignedTasks: " + JSON.stringify(this.myAssignedTasks));

  }

  refreshTasklist(): void {
    console.log("--> refresh() refreshing");

    this.tasklistDataService.getAll().subscribe((data: any[]) => {
      this.tasklistList = data;

      this.filterTasklist.length = 0;
      this.filterTasklist.push({ id: -1, name: "Alla listor", owner: null, members: [], send_notification: false });
      for (let tasklist of this.tasklistList) {
        this.filterTasklist.push(tasklist);
      }
    });

    this.tasklistDataService.getAllTasksVisible().subscribe((data: any[]) => {
      this.rawTasklist = data;
      this.sortTasks();
    });
  }

  editTaskDialog(task: Task): void {
    let createNewTask = false;

    if (task == null) {
      createNewTask = true;
      task = this.tasklistDataService.getEmptyTaskObject();
    }

    console.log("task: '" + task + "' createNewTask: '" + createNewTask + "'");
    console.log(JSON.stringify(task));

    this.dialog.open(EdittaskDialogComponent, { data: task, width: '500px' }).afterClosed().subscribe(
      data => {
        if (createNewTask) {
          this.tasklistDataService.createNewTask(data).subscribe(res => {
            console.log("create ok");
            this.refreshTasklist();
          },
            err => {
              console.log("create fail " + err);
            });
        }
        else {
          this.tasklistDataService.updateTask(task.id, data).subscribe(res => {
            console.log("update ok");
            this.refreshTasklist();
          },
            err => {
              console.log("update fail " + err);
            });
        }
      });
  }

  filterTasksByTasklist(selectedTasklist: any): any[] {
    return this.myTasks.filter(task => task.tasklist === selectedTasklist.id);
  }

  onTaskListChange(event): void {
    console.log("tasklistwork.component.ts onTaskListChange()");
    console.log(event);
    this.selectedFilterListId = event.value.id;
    this.sortTasks();

  }

}
