import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  public detailNavEnabled: Boolean;
  public navDrawerEnabled: Boolean;

  constructor() {
    this.detailNavEnabled = false;
    this.navDrawerEnabled = false;
  }

  public showDetailNav() {
    setTimeout(() => {
      this.detailNavEnabled = true;
      console.log("showDetailNav() detailNavEnabled: " + this.detailNavEnabled);
    });
  }

  public hideDetailNav() {
    setTimeout(() => {
      this.detailNavEnabled = false;
      console.log("hideDetailNav() detailNavEnabled: " + this.detailNavEnabled);
    });
  }

  public toggleDetailNav() {
    this.detailNavEnabled = !this.detailNavEnabled;
  }

  public showNavDrawer() {
    setTimeout(() => {
      this.navDrawerEnabled = true;
    });
  }

  public clearSidebars() {
    setTimeout(() => {
      this.detailNavEnabled = false;
      this.navDrawerEnabled = false;
    });

  }

}
