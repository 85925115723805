import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TasklistComponent } from './tasklist/tasklist.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NavComponent } from './nav/nav.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthenticationService } from './authentication/services/authentication.service';
import { appInitializer } from './authentication/helpers/initializer';
import { JwtInterceptor } from './authentication/helpers/jwt.interceptor';
import { ErrorInterceptor } from './authentication/helpers/auth-error.interceptor';

import { BuildingmanagerComponent } from './buildingmanager/buildingmanager.component';
import { EditrealestateDialogComponent } from './buildingmanager/dialogs/editrealestate-dialog/editrealestate-dialog.component';
import { EditmunicipalityDialogComponent } from './buildingmanager/dialogs/editmunicipality-dialog/editmunicipality-dialog.component';

import { PersonnellistComponent } from './personnellist/personnellist.component';

import { EditownerDialogComponent } from './buildingmanager/dialogs/editowner-dialog/editowner-dialog.component';
import { ShowrealestateDialogComponent } from './buildingmanager/dialogs/showrealestate-dialog/showrealestate-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditbuildingDialogComponent } from './buildingmanager/dialogs/editbuilding-dialog/editbuilding-dialog.component';
import { EditentranceDialogComponent } from './buildingmanager/dialogs/editentrance-dialog/editentrance-dialog.component';
import { BuildingdetailComponent } from './buildingmanager/components/buildingdetail/buildingdetail.component';

import { MapComponent } from './map/map.component';

import { ApartementmanagerComponent } from './apartementmanager/apartementmanager.component';
import { EditApartementDialogComponent } from './apartementmanager/dialogs/edit-apartement-dialog/edit-apartement-dialog.component';
import { ApartementlistComponent } from './apartementmanager/components/apartementlist/apartementlist.component';

import { TasklistSettingsComponent } from './tasklist/components/tasklist-settings/tasklist-settings.component';
import { EditPersonnelDialogComponent } from './personnellist/dialogs/edit-user-dialog/edit-personnel-dialog.component';
import { EditTasklistDialogComponent } from './tasklist/dialogs/edittasklist-dialog/edittasklist-dialog.component';

import { LoginComponent } from './authentication/components/login/login.component';
import { InterestlistComponent } from './interestlist/interestlist.component';
import { EditinterestDialogComponent } from './interestlist/dialogs/editinterest-dialog/editinterest-dialog.component';
import { MatSortModule } from '@angular/material/sort';

import { InterestdetailComponent } from './interestlist/components/interestdetail/interestdetail.component';
import { EdittaskDialogComponent } from './tasklist/dialogs/edittask-dialog/edittask-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule} from '@angular/flex-layout';
import { TaskdetailComponent } from './tasklist/components/taskdetail/taskdetail.component';
import { TasklisttableComponent } from './tasklist/components/tasklisttable/tasklisttable.component';
import { TasklistallComponent } from './tasklist/components/tasklistall/tasklistall.component';
import { ProgresslogComponent } from './progresslog/progresslog.component';
import { EditProgresslogDialogComponent } from './progresslog/dialogs/edit-progresslog-dialog/edit-progresslog-dialog.component';
import { AuthGuard } from './authentication/helpers/auth-guard';
import { SettaskstateDialogComponent } from './tasklist/dialogs/settaskstate-dialog/settaskstate-dialog.component';
import { TasklistworkComponent } from './tasklist/components/tasklistwork/tasklistwork.component';
import { PersonnelDetailComponent } from './personnellist/components/personnel-detail/personnel-detail.component';
import { ChangePasswordDialogComponent } from './personnellist/dialogs/change-password-dialog/change-password-dialog.component';



@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    TasklistComponent,
    BuildingmanagerComponent,
    EditrealestateDialogComponent,
    EditmunicipalityDialogComponent,
    PersonnellistComponent,
    EditownerDialogComponent,
    ShowrealestateDialogComponent,
    EditbuildingDialogComponent,
    EditentranceDialogComponent,
    MapComponent,
    ApartementmanagerComponent,
    EditApartementDialogComponent,
    BuildingdetailComponent,
    TasklistSettingsComponent,
    EditPersonnelDialogComponent,
    EditTasklistDialogComponent,
    LoginComponent,
    ApartementlistComponent,
    InterestlistComponent,
    EditinterestDialogComponent,
    InterestdetailComponent,
    EdittaskDialogComponent,
    TaskdetailComponent,
    TasklisttableComponent,
    TasklistallComponent,
    ProgresslogComponent,
    EditProgresslogDialogComponent,
    SettaskstateDialogComponent,
    TasklistworkComponent,
    PersonnelDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSnackBarModule,
  ],
  exports: [
    MatCardModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
