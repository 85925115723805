<h1 mat-dialog-title>Byt Lösenord</h1>
<mat-dialog-content [formGroup]="form" fxFill fxLayout="column">
    <div fxLayout="row" fxFill>
        <mat-form-field fxFlex>
            <mat-label>Nytt Lösenord</mat-label>
            <input matInput formControlName="password">
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field fxFlex>
            <mat-label>Upprepa Lösenord</mat-label>
            <input matInput formControlName="confirm_password">
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFill></div>
</mat-dialog-content>
<div mat-dialog-actions fxLayout="column">
    <div fxFlayout="row" fxFill>
        <mat-divider fxFlex></mat-divider>
    </div>
    <div class="padding-16" fxFlayout="row" align="end" fxFill >
        <button mat-button (click)="cancelDialog()">Avbryt</button>
        <button mat-raised-button color="primary" (click)="saveDialog()">Spara</button>
    </div>
</div>
