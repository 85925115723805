import { NavService } from './../../nav/services/nav.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private COOKIE_REFRESH = "JWT_REFRESH";

  public static readonly CURRENTUSER_CHANGE_EVENT = "CURRENTUSER_CHANGE_EVENT";

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public authenticated: boolean;
  private currentUserData: UserData;

  private eventEmitter: EventEmitter<string>;

  public REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/";

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private navService: NavService,
  ) {
    this.eventEmitter = new EventEmitter<string>();

    console.log(window.location);
    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser = this.currentUserSubject.asObservable();
    this.authenticated = false;
    this.currentUserData = this.getEmptyUserDataObject();
  }

  getChangeEmitter(): EventEmitter<string> {
    return this.eventEmitter;
  }

  emitChangeEvent(change: string) {
    this.eventEmitter.emit(change);
  }

  public get userValue(): User {
    return this.currentUserSubject.value;
  }

  public getCurrentUserDataValue(): UserData {
    return this.currentUserData;
  }

  login(email: string, password: string) {
    console.log("Sending login request for email: " + email);
    return this.http.post<any>(this.REST_API_SERVER +"api-token-auth/", { email, password }, { withCredentials: true })
      .pipe(map(user => {
        console.log("Received login response: " + JSON.stringify(user));

        //put the refresh token in our cookie
        this.cookieService.set(this.COOKIE_REFRESH, user['refresh']);

        //process the access cookie
        let tArray = user['access'].split('.');
        this.currentUserSubject.next(user);

        this.startRefreshTokenTimer();
        this.authenticated = true;
        this.getCurrentUserData();
        this.navService.showNavDrawer();
        return user;
      }));
  }

  logout() {
    this.http.post<any>(this.REST_API_SERVER + "users/revoke-token/", {}, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    this.cookieService.delete(this.COOKIE_REFRESH);
    this.currentUserSubject.next(null);
    this.authenticated = false;
    this.navService.clearSidebars();
    this.router.navigate(['/login']);
  }

  getCurrentUserData() {
    console.log("Getting current user data");

    this.http.get(this.REST_API_SERVER + 'accounts/', this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      ).subscribe((data: UserData) => {
        this.currentUserData = data[0];
        console.log("Current user data: " + JSON.stringify(this.currentUserData));
        this.emitChangeEvent(AuthenticationService.CURRENTUSER_CHANGE_EVENT);
      });
  }

  refreshToken() {
    console.log("Refreshing token");

    let refreshToken = "";

    //load the refresh token from the cookie and try to get a new access token
    if (this.cookieService.check(this.COOKIE_REFRESH)) {
      refreshToken = this.cookieService.get(this.COOKIE_REFRESH);
    }
    else {
      this.router.navigate(['/login']);
    }

    return this.http.post<any>(this.REST_API_SERVER + "api-token-refresh/", { refresh: refreshToken }, { withCredentials: true })
      .pipe(map((user) => {
        console.log("Received refresh response: " + JSON.stringify(user));
        let tArray = user['access'].split('.');
        this.currentUserSubject.next(user);
        this.navService.showNavDrawer();
        this.startRefreshTokenTimer();
        this.authenticated = true;
        this.getCurrentUserData();
        return user;
      }));
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    const jwtToken = JSON.parse(atob(this.userValue.access.split('.')[1]));
    //console.log("----->> startRefreshTokenTimer token : " + JSON.stringify(jwtToken));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }


  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Personnel-data.service: Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


  getEmptyUserDataObject(): UserData {
    return {
      id: null,
      email: '',
      username: 'EMPTY',
      created_at: null,
      updated_at: null,
      first_name: '',
      last_name: '',
      tagline: '',
      role_companyadmin: false,
      role_maintenance: false,
      role_administration: false,
    }
  }
}

export interface UserData {
  id: number;
  email: string;
  username: string;
  created_at: Date;
  updated_at: Date;
  first_name: string;
  last_name: string;
  tagline: string;
  role_companyadmin: boolean;
  role_maintenance: boolean;
  role_administration: boolean;
}
