<mat-card appearance="outlined" fxFill>
	<mat-card-header>
		<mat-card-title>
			<h2>Intresseanmälan</h2>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content fxFlexFill>
		<mat-tab-group animationDuration="200ms" fxFlexFill>
			<mat-tab label="Uppgifter">
			<div fxLayout="column" fxFlexFill>
				<div mat-dialog-content [formGroup]="form" fxFlexFill>
					<mat-card appearance="outlined" class="mat-elevation-z2" fxLayout="column">
						<h3>Personuppgifter</h3>
						<div fxLayout="row" fxFlexFill>
							<mat-form-field fxFlex>
								<mat-label>Sökandes Namn</mat-label>
								<input matInput formControlName="name" required>
							</mat-form-field>
							<mat-form-field>
								<mat-label>Personnummer</mat-label>
								<input matInput formControlName="ssn" required>
							</mat-form-field>
						</div>
						<div fxLayout="row">
							<mat-form-field fxFlex>
								<mat-label>e-post</mat-label>
								<input matInput formControlName="email">
							</mat-form-field>
							<mat-form-field>
								<mat-label>Telefonnummer</mat-label>
								<input matInput formControlName="phone">
							</mat-form-field>
						</div>
						<div fxLayout="row">
							<mat-form-field fxFlex>
								<mat-label>Adress</mat-label>
								<input matInput formControlName="currentStreet">
							</mat-form-field>
							<mat-form-field>
								<mat-label>Postnummer</mat-label>
								<input matInput formControlName="currentAreacode">
							</mat-form-field>
							<mat-form-field>
								<mat-label>Ort</mat-label>
								<input matInput formControlName="currentCity">
							</mat-form-field>
						</div>
						<div fxLayout="row">
							<mat-form-field fxFlex>
								<mat-label>Nuvarande boende/hyresvärd</mat-label>
								<input matInput formControlName="currentLandlord">
							</mat-form-field>
						</div>
					</mat-card>
					<mat-card appearance="outlined" class="mat-elevation-z2" fxLayout="column">
						<h3>Arbete och inkomst</h3>
						<mat-form-field>
							<mat-label>Inkomsstyp</mat-label>
							<input matInput formControlName="employmentType" required>
						</mat-form-field>
						<mat-form-field>
							<mat-label>Årsinkomst</mat-label>
							<input matInput formControlName="employmentYearly" required>
						</mat-form-field>

						<div fxLayout="row" fxFlexFill>
							<mat-form-field fxFlex>
								<mat-label>Arbetsgivare</mat-label>
								<input matInput formControlName="employer">
							</mat-form-field>
						</div>
						<div fxLayout="row">
							<mat-form-field fxFlex>
								<mat-label>Kontakt hos arbetsgivare </mat-label>
								<input matInput formControlName="employmentContact">
							</mat-form-field>
						</div>
					</mat-card>
					<mat-card appearance="outlined" class="mat-elevation-z2" fxLayout="column">
						<h3>Önskat boende</h3>
						<mat-form-field>
							<mat-label>Storlek lägenhet</mat-label>
							<input matInput formControlName="apartementRok" required>
						</mat-form-field>
						<div fxLayout="row">
							<mat-form-field fxFlex>
								<mat-label>Antal vuxna</mat-label>
								<input matInput formControlName="apartementAdults" required>
							</mat-form-field>
							<mat-form-field fxFlex>
								<mat-label>Antal barn</mat-label>
								<input matInput formControlName="apartementChildren">
							</mat-form-field>
						</div>
						<mat-form-field>
							<mat-label>Övriga önskemål</mat-label>
							<textarea matInput formControlName="comment"></textarea>
						</mat-form-field>
					</mat-card>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Hantering">

		</mat-tab>
		</mat-tab-group>
	</mat-card-content>
</mat-card>
