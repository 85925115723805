import { Personnel, PersonnelDataService } from 'src/app/personnellist/personnel-data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tasklist, TasklistDataService } from '../../services/tasklist-data.service';
import { Task } from '../../services/tasklist-data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EdittaskDialogComponent } from '../../dialogs/edittask-dialog/edittask-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SettaskstateDialogComponent } from '../../dialogs/settaskstate-dialog/settaskstate-dialog.component';
import { NavService } from 'src/app/nav/services/nav.service';
import { ProgresslogDataService } from 'src/app/progresslog/services/progresslog-data.service';


@Component({
  selector: 'app-taskdetail',
  templateUrl: './taskdetail.component.html',
  styleUrls: ['./taskdetail.component.css']
})
export class TaskdetailComponent implements OnInit {

  taskId = -1;
  task: Task;
  form: UntypedFormGroup;
  closebuttonVisble = false;

  personnelList = [];
  task_lists = [];
  priority_list = [];


  constructor(
    private route: ActivatedRoute,
    private tasklistDS: TasklistDataService,
    private personnelDataService: PersonnelDataService,
    private dialog: MatDialog,
    private progressLogDS: ProgresslogDataService,
    private navService: NavService) {

    this.task = tasklistDS.getEmptyTaskObject();

    //create a form for a task
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.task.id),

      completed: new UntypedFormControl(this.task.completed),
      completed_by: new UntypedFormControl(this.task.completed_by),
      completed_date: new UntypedFormControl(this.task.completed_date),
      completeby_date: new UntypedFormControl(this.task.completeby_date),
      created_by: new UntypedFormControl(this.task.created_by),
      created_date: new UntypedFormControl(this.task.created_date),

      title: new UntypedFormControl(this.task.title),
      description: new UntypedFormControl(this.task.description),
      tasklist: new UntypedFormControl(this.task.tasklist),
      assigned_to: new UntypedFormControl(this.task.assigned_to),
      priority: new UntypedFormControl(this.task.priority),

      foreign_id: new UntypedFormControl(this.task.foreign_id),
      internal_id: new UntypedFormControl(this.task.internal_id),
      master_key: new UntypedFormControl(this.task.master_key),
      text_location: new UntypedFormControl(this.task.text_location),
      contact_phone: new UntypedFormControl(this.task.contact_phone),

      progresslog: new UntypedFormControl(this.task.progresslog),
    });

    this.form.disable();
  }

  updateForm(data: Task) {
    console.log("updateForm " + JSON.stringify(data));
    this.form.setValue(data);
  }


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.taskId = Number(params.get('taskId'));
      console.log("url taskId " + this.taskId);

      this.tasklistDS.getTaskById(this.taskId).subscribe((data: Task) => {
        console.log("taskById = " + JSON.stringify(data));
        this.task = data;
        this.form.setValue(this.task);

        if (data.created_by == data.assigned_to) {
          this.closebuttonVisble = true;
        }
      });
    });

    this.personnelDataService.getAll().subscribe((data: Personnel[]) => { this.personnelList = data });
    this.tasklistDS.getAll().subscribe((data: Tasklist[]) => { this.task_lists = data });

  }

  editTaskDialog(task: Task): void {
    console.log(JSON.stringify(task));

    this.dialog.open(EdittaskDialogComponent, { data: task, width: '500px' }).afterClosed().subscribe(
      data => {
        this.tasklistDS.updateTask(task.id, data).subscribe(res => {
          console.log("update ok");
          this.tasklistDS.getTaskById(this.taskId).subscribe((data: Task) => {
            this.task = data;
            this.updateForm(this.task);
            this.tasklistDS.emitChangeEvent(TasklistDataService.TASK_CHANGE_EVENT);
          });
        },
          err => {
            console.log("update fail " + err);
          });
      });
  }

  markTaskAsDone(task: Task): void {
    console.log(JSON.stringify(task));

    this.dialog.open(SettaskstateDialogComponent, { data: task, width: '500px' }).afterClosed().subscribe(
      data => {
        if (data == undefined) {
          return;
        }

        task.assigned_to = task.created_by;
        this.tasklistDS.updateTask(task.id, task).subscribe(res => {
          //console.log("update ok");
          this.progressLogDS.createProgresslogEntryOnId(task.progresslog, data).subscribe(res => {
            this.tasklistDS.emitChangeEvent(TasklistDataService.TASK_CHANGE_EVENT);
          });
        });
      });
  }


  markTaskAsCompleted(task: Task): void {
    console.log(JSON.stringify(task));

    this.dialog.open(SettaskstateDialogComponent, { data: task, width: '500px' }).afterClosed().subscribe(
      data => {
        if (data == undefined) {
          return;
        }

        task.assigned_to = task.created_by;
        task.completed_by = task.completed_by;
        task.completed = true;
        this.tasklistDS.updateTask(task.id, task).subscribe(res => {
          //console.log("update ok");
          this.progressLogDS.createProgresslogEntryOnId(task.progresslog, data).subscribe(res => {
            this.tasklistDS.emitChangeEvent(TasklistDataService.TASK_CHANGE_EVENT);
          });
        });
      });
  }

  closeDetailNav(): void {
    this.navService.hideDetailNav();
  }
}
