import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditPersonnelDialogComponent } from './dialogs/edit-user-dialog/edit-personnel-dialog.component';

import { PersonnelDataService, Personnel } from './personnel-data.service';
import { NavService } from '../nav/services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-personnellist',
  templateUrl: './personnellist.component.html',
  styleUrls: ['./personnellist.component.css']
})
export class PersonnellistComponent implements OnInit {

  personnelList = [];

  displayedColumns: string[] = ['name', 'username', 'phone', 'email'];

  constructor(
    private dialog: MatDialog,
    private pdService: PersonnelDataService,
    private navService: NavService,
    private route: Router) { }

  ngOnInit(): void {
    this.pdService.getAll().subscribe((data: Personnel[]) => { this.personnelList = data });
  }


  refreshPersonnellist(): void {
    this.pdService.getAll().subscribe((data: any[]) => {
      this.personnelList = data;
    });
  }

  showPersonDetail(person: Personnel): void {
    this.navService.showDetailNav();
    this.route.navigate(['', { outlets: { detailOutlet: ['personneldetail', person.id] } }]);
  }

  editPersonnelDialog(personnel: Personnel) {

    let createNewPersonnel = false;

    if (personnel == null) {
      createNewPersonnel = true;
      personnel = this.pdService.getEmptyPersonnelObject();
    }

    this.dialog.open(EditPersonnelDialogComponent, { data: personnel, width: '400px' }).afterClosed().subscribe(
      data => {
        if (createNewPersonnel) {
          this.pdService.create(data).subscribe(res => {
            console.log("create ok");
            this.refreshPersonnellist();
          },
            err => {
              console.log("create fail " + err);
            });
        }
        else {
          this.pdService.update(personnel.id, data).subscribe(res => {
            console.log("update ok");
            this.refreshPersonnellist();
          },
            err => {
              console.log("update fail " + err);
            });
        }
      });
  }
}
