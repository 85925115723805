<h1 mat-dialog-title>Redigera Entré</h1>
<div mat-dialog-content [formGroup]="form" fxLayout='column'>

    <div fxLayout="row">
        <mat-form-field>
            <mat-label>Gatunamn</mat-label>
            <input matInput formControlName="streetname">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Gatunummer</mat-label>
            <input matInput formControlName="number">
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field>
            <mat-label>postnummer</mat-label>
            <input matInput formControlName="zipcode">
        </mat-form-field>
        <mat-form-field>
            <mat-label>postort</mat-label>
            <input matInput formControlName="city">
        </mat-form-field>
    </div> 
  <mat-divider></mat-divider>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Avbryt</button>
  <button mat-button (click)="onSaveClick()">Spara</button>
</div>