import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class MunicipalityDataService {

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/";

  private municipalityList = null;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',

    })
  }

  constructor( private httpClient: HttpClient,
               ) {

  }

  getMunicipalityName(id: number): string {
    if (this.municipalityList == null){
      this.getAll().subscribe((data: MunicipalityData[]) => {
        this.municipalityList = data;

        for (let municipality of this.municipalityList){
          if (municipality.id == id){
            return municipality.name;
          }
        }
      })

    } else {

      for (let municipality of this.municipalityList){
        if (municipality.id == id){
          return municipality.name;
        }
      }
      return "Undefined";
    }
    return "Undefined";
  }

  create(municipality): Observable<MunicipalityData> {
    this.municipalityList = null;

    return this.httpClient.post<MunicipalityData>(this.REST_API_SERVER + 'municipalities/', JSON.stringify(municipality), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getById(id): Observable<MunicipalityData> {
    return this.httpClient.get<MunicipalityData>(this.REST_API_SERVER + 'municipalities/' + id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getAll(): Observable<MunicipalityData[]> {
    return this.httpClient.get<MunicipalityData[]>(this.REST_API_SERVER + 'municipalities/')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  update(id, municipality): Observable<MunicipalityData> {
    this.municipalityList = null;

    return this.httpClient.put<MunicipalityData>(this.REST_API_SERVER + 'municipalities/' + id + "/", JSON.stringify(municipality), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id){
    this.municipalityList = null;

    return this.httpClient.delete<MunicipalityData>(this.REST_API_SERVER + 'municipalities/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }
}

export interface MunicipalityData{
  id: number;
  name: string;
}
