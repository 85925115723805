import { EntranceData } from './../../../buildingmanager/services/entrance-data.service';
import { RealestateData } from 'src/app/buildingmanager/services/buildingmanager-data.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApartementData } from '../../services/apartement-data.service';

@Component({
  selector: 'app-edit-apartement-dialog',
  templateUrl: './edit-apartement-dialog.component.html',
  styleUrls: ['./edit-apartement-dialog.component.css']
})
export class EditApartementDialogComponent implements OnInit {

  form: UntypedFormGroup;
  realestate: RealestateData;
  apartement: ApartementData;
  entranceList: EntranceData[];

  constructor(
    public dialogRef: MatDialogRef<EditApartementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;

    console.log("data: " + JSON.stringify(data));

    this.apartement = <ApartementData>data.apartement;
    this.realestate = <RealestateData>data.realestate;
    this.entranceList = [];

    //  this.entranceList = this.realestate.buildings.find(building => building.id == this.apartement.building).street_entrances;

    console.log("apartement: " + JSON.stringify(this.apartement));

    this.form = new UntypedFormGroup({
      identifier: new UntypedFormControl(this.apartement.identifier),
      skvIdentifier: new UntypedFormControl(this.apartement.skvIdentifier),
      area: new UntypedFormControl(this.apartement.area),
      level: new UntypedFormControl(this.apartement.level),
      rok: new UntypedFormControl(this.apartement.rok),
      building: new UntypedFormControl(this.apartement.building),
      entrance: new UntypedFormControl(this.apartement.entranceStreetAddress),
      powermeterId: new UntypedFormControl(""),
    });
  }

  ngOnInit(): void {
    //get all entrances
    this.realestate.buildings.forEach(building => {
      building.street_entrances.forEach(entrance => {
        this.entranceList.push(entrance);
      });
    });
  }

  updateEntranceSelect(newValue) {
    console.log("newValue : " + newValue.value);
    this.entranceList = [];

    let building = this.realestate.buildings.find(building => building.id == newValue.value)

    this.entranceList = building.street_entrances;
    console.log(building);
  }

  onCancelClick() {
    console.log("Close and discard Aparteent dialog");
    this.dialogRef.close();
  }


  onSaveClick() {
    console.log("Save apartement dialog");
    this.dialogRef.close(this.form.value);
  }
}
