import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RealestateData } from '../../services/buildingmanager-data.service';
import { MunicipalityData, MunicipalityDataService } from '../../services/municipality-data.service';
import { EditmunicipalityDialogComponent } from '../editmunicipality-dialog/editmunicipality-dialog.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Owner, OwnerDataService } from '../../services/owner-data.service';

@Component({
  selector: 'app-editrealestate-dialog',
  templateUrl: './editrealestate-dialog.component.html',
  styleUrls: ['./editrealestate-dialog.component.css']
})
export class EditrealestateDialogComponent implements OnInit {

  municipalityList: MunicipalityData[];
  ownerList: Owner[];

  form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<EditmunicipalityDialogComponent>,
    private dialog: MatDialog,
    private mdService: MunicipalityDataService,
    private ownerService: OwnerDataService,
    @Inject(MAT_DIALOG_DATA) public data: RealestateData
  ) {
    dialogRef.disableClose = true;

    this.form = new UntypedFormGroup({
      owner: new UntypedFormControl(data.owner),
      name: new UntypedFormControl(data.name),
      municipality: new UntypedFormControl(data.municipality),
      area_grounds: new UntypedFormControl(data.area_grounds),
   });
  }

  ngOnInit(){
    this.mdService.getAll().subscribe((data: MunicipalityData[])=> {this.municipalityList = data});
    this.ownerService.getAll().subscribe((data: Owner[]) => {this.ownerList = data});
  }


  newMunicipalityDialog(){
    this.dialog.open(EditmunicipalityDialogComponent).afterClosed().subscribe(
      data => {
        console.log("Dialog output:", data);
        this.mdService.create(data).subscribe(res=>{
          console.log("create ok");
          this.mdService.getAll().subscribe((data: MunicipalityData[])=> {this.municipalityList = data});
        },
        err => {
          console.log("create fail " + err );
        });
    })
  }


  realestateDialogSave(){
    console.log("Save Realesteate dialog")
    this.dialogRef.close(this.form.value)
  }


  realestateDialogClose(){
    console.log("Close and discard Realesteate dialog")
    this.dialogRef.close()
  }

}


