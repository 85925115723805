import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'ol/proj';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PersonnelDataService {

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/";

  private personnelCache: Personnel[] = [];
  private personnelCacheLoaded: boolean = false;
  private personnelCacheTimestamp: Date = null;
  private personnelCacheRequestSent: boolean = false;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(
    private httpClient: HttpClient,
  ) {

  }

  create(personnel): Observable<Personnel> {
    return this.httpClient.post<Personnel>(this.REST_API_SERVER + 'personnel/', JSON.stringify(personnel), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getById(id): Observable<Personnel> {
    return this.httpClient.get<Personnel>(this.REST_API_SERVER + 'personnel/' + id)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getAll(): Observable<Personnel[]> {
    return this.httpClient.get<Personnel[]>(this.REST_API_SERVER + 'personnel/')
      .pipe(
        catchError(this.errorHandler)
      )
  }

  update(id, personnel): Observable<Personnel> {
    return this.httpClient.put<Personnel>(this.REST_API_SERVER + 'personnel/' + id + '/', JSON.stringify(personnel), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  changePassword(id, data): Observable<Personnel> {
    return this.httpClient.put<Personnel>(this.REST_API_SERVER + 'accounts/changepassword/' + id + '/', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }


  delete(id) {
    return this.httpClient.delete<Personnel>(this.REST_API_SERVER + 'personnel/' + id, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Personnel-data.service: Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  public getEmptyPersonnelObject(): Personnel {
    return {
      id: null,
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      tagline: '',
      phone_number_1: '',
      phone_number_2: '',
      is_admin: false,
      is_staff: false,
      created_at: null,
      updated_at: null,
      role_maintenance: false,
      role_administration: false,
    }
  }

  public getNameFromId(id: number): any {
    let cacheAge = 0;

    if (this.personnelCacheTimestamp != null) {
      cacheAge = new Date().getTime() - this.personnelCacheTimestamp.getTime();
    }

    if ((!this.personnelCacheLoaded && !this.personnelCacheRequestSent) || (cacheAge > (10000 * 60 * 10))) {
      console.log("refreshing usercache");

      this.personnelCacheRequestSent = true;

      this.getAll().subscribe((data: Personnel[]) => {
        this.personnelCache = data;
        this.personnelCacheLoaded = true;
        this.personnelCacheTimestamp = new Date();
        this.personnelCacheRequestSent = false;
      });
    }

    for (let personnel of this.personnelCache) {
      if (personnel.id == id) {
        return personnel.username;
      }
    }

    return "...";
  }
}

export interface Personnel {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  tagline: string;

  phone_number_1: string;
  phone_number_2: string;

  is_admin: boolean;
  is_staff: boolean;

  role_maintenance: boolean;
  role_administration: boolean;

  created_at: Date;
  updated_at: Date;
}
