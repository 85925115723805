import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgresslogDataService {

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/";

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

  constructor(
    private httpClient: HttpClient,
  ) { }


  createProgresslogEntry(progresslog: ProgresslogData, progresslogEntry): Observable<ProgresslogEntryData> {
    return this.createProgresslogEntryOnId(progresslog.id, progresslogEntry);
  }


  createProgresslogEntryOnId(progresslogId: Number, progresslogEntry): Observable<ProgresslogEntryData> {
    return this.httpClient.post<ProgresslogEntryData>(this.REST_API_SERVER + 'progresslog/' + progresslogId + '/logentry/', JSON.stringify(progresslogEntry), this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }


  getProgresslog(id): Observable<ProgresslogEntryData> {
    return this.httpClient.get<ProgresslogEntryData>(this.REST_API_SERVER + 'progresslog/' + id + "/")
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getEmptyProgresslogEntryObject(): ProgresslogEntryData {
    return {
      id: null,
      entry: '',
      created_date: null,
      created_by: null,
    }
  }

  getEmptyProgresslogDataObject(): ProgresslogData {
    return {
      id: null,
      created_date: null,
      updated_date: null,
      entries: [],
    }
  }

  errorHandler(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Personnel-data.service: Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}
}

export interface ProgresslogData {
  id: number;
  created_date: Date;
  updated_date: Date;
  entries: ProgresslogEntryData[];
}

export interface ProgresslogEntryData {
	id: number;
  entry: string;
  created_date: Date;
  created_by: number;
}
