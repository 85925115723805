import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class OwnerDataService {

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/";


	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	}

	constructor(private httpClient: HttpClient,
	) {

	}

	create(ownerData): Observable<Owner> {
		return this.httpClient.post<Owner>(this.REST_API_SERVER + 'owners/', JSON.stringify(ownerData), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	getById(id): Observable<Owner> {
		return this.httpClient.get<Owner>(this.REST_API_SERVER + 'owners/' + id)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	getAll(): Observable<Owner[]> {
		return this.httpClient.get<Owner[]>(this.REST_API_SERVER + 'owners/')
			.pipe(
				catchError(this.errorHandler)
			)
	}

	update(id, ownerData): Observable<Owner> {
		return this.httpClient.put<Owner>(this.REST_API_SERVER + 'owners/' + id, JSON.stringify(ownerData), this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	delete(id) {
		return this.httpClient.delete<Owner>(this.REST_API_SERVER + 'owners/' + id, this.httpOptions)
			.pipe(
				catchError(this.errorHandler)
			)
	}

	errorHandler(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.log(errorMessage);
		return throwError(errorMessage);
	}
}


export interface Owner {
	id: number;
	name: string;
	shorthand: string;
}
