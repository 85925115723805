import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RealestateData } from './buildingmanager-data.service';
import { EntranceData } from './entrance-data.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingDataService {

  private REST_API_SERVER = window.location.protocol + "//" + window.location.hostname + ":8000/api/v2/realestates/";

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor( private httpClient: HttpClient,
              ) {

  }

  create(realestate: RealestateData, building: BuildingData): Observable<BuildingData> {
    return this.httpClient.post<BuildingData>(this.REST_API_SERVER + realestate.id + '/buildings/', JSON.stringify(building), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getById(id: number): Observable<BuildingData> {
    return this.httpClient.get<BuildingData>(this.REST_API_SERVER + id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getAll(): Observable<BuildingData[]> {
    return this.httpClient.get<BuildingData[]>(this.REST_API_SERVER)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  update(id: number, building: BuildingData): Observable<BuildingData> {
    return this.httpClient.put<BuildingData>(this.REST_API_SERVER + id, JSON.stringify(building), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id: number){
    return this.httpClient.delete<BuildingData>(this.REST_API_SERVER + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }
}


export interface BuildingData{
  id: number;
  name: string;
  area_building: number;
  building_year: number;
  street_entrances: EntranceData[];
}
