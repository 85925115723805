<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
  <mat-card-header>
    <mat-card-title>Lägenheter</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row">
      <!-- button mat-button (click)="newApartementDialog(realestate, entranceList)">Ny Lägenhet</button-->
    </div>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="mat-elevation-z2 card-padding-16">
  <mat-card-content>

    <table mat-table matSort [dataSource]="apartementList" fxFlex>
      <ng-container matColumnDef="checked">
        <th mat-header-cell *matHeaderCellDef class="w-3em">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let realestate">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggleSelect(realestate) : null"
            [checked]="selection.isSelected(realestate)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="hyresobjekt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">Hyresobjekt</th>
        <td mat-cell *matCellDef="let apartement"> {{apartement.identifier}} </td>
      </ng-container>
      <ng-container matColumnDef="fastighet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">Fastighet</th>
        <td mat-cell *matCellDef="let apartement"> {{apartement.realestate.name}} </td>
      </ng-container>
      <ng-container matColumnDef="skvIdentifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">SKV Nummer</th>
        <td mat-cell *matCellDef="let apartement"> {{apartement.skvIdentifier}} </td>
      </ng-container>
      <ng-container matColumnDef="rok">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">RoK</th>
        <td mat-cell *matCellDef="let apartement"> {{apartement.rok}} </td>
      </ng-container>
      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">Area</th>
        <td mat-cell *matCellDef="let apartement"> {{apartement.area}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
