<h1 mat-dialog-title>Redigera Uppdragslista</h1>
<mat-dialog-content [formGroup]="form">


  <mat-form-field fxFill>
    <mat-label>Namn</mat-label>
    <input matInput formControlName="name">
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Ägare</mat-label>
    <mat-select formControlName="owner">
      <mat-option *ngFor="let personnel of personnelList" [value]="personnel.id">
        {{personnel.first_name}} {{ personnel.last_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFill>
    <mat-label>Medlemmar</mat-label>
    <mat-select formControlName="members" multiple>
      <mat-option *ngFor="let personnel of personnelList" [value]="personnel.id">
        {{ personnel.first_name }} {{ personnel.last_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div>
    <mat-label>Skicka notifieringar</mat-label>
    <mat-checkbox formControlName="send_notification">Notifiera</mat-checkbox>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="cancelDialog()">Avbryt</button>
  <button mat-stroked-button (click)="saveDialog()">Spara</button>
</div>
